export const banks = [
    {
        "COMPE": "001",
        "ISPB": "00000000",
        "Document": "00.000.000/0001-91",
        "LongName": "Banco do Brasil S.A.",
        "ShortName": "BCO DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.bb.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7109988-03:00",
        "DateUpdated": "2022-07-22T07:49:18.399726+00:00"
    },
    {
        "COMPE": "003",
        "ISPB": "04902979",
        "Document": "04.902.979/0001-44",
        "LongName": "Banco da Amazônia S.A.",
        "ShortName": "BCO DA AMAZONIA S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.bancoamazonia.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114564-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5485925+00:00"
    },
    {
        "COMPE": "004",
        "ISPB": "07237373",
        "Document": "07.237.373/0001-20",
        "LongName": "Banco do Nordeste do Brasil S.A.",
        "ShortName": "BCO DO NORDESTE DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": "https://www.banconordeste.gov.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114613-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493632+00:00"
    },
    {
        "COMPE": "007",
        "ISPB": "33657248",
        "Document": "33.657.248/0001-89",
        "LongName": "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
        "ShortName": "BNDES",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2014-06-30",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115088-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3507277+00:00"
    },
    {
        "COMPE": "010",
        "ISPB": "81723108",
        "Document": "81.723.108/0001-04",
        "LongName": "CREDICOAMO CREDITO RURAL COOPERATIVA",
        "ShortName": "CREDICOAMO",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2012-12-10",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115464-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3596292+00:00"
    },
    {
        "COMPE": "011",
        "ISPB": "61809182",
        "Document": "61.809.182/0001-30",
        "LongName": "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
        "ShortName": "C.SUISSE HEDGING-GRIFFO CV S/A",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-06-28",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115317-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115319-03:00"
    },
    {
        "COMPE": "012",
        "ISPB": "04866275",
        "Document": "04.866.275/0001-63",
        "LongName": "Banco Inbursa S.A.",
        "ShortName": "BANCO INBURSA",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consigando",
            "Pessoal",
            "Veiculos e Outros Créditos."
        ],
        "Url": "https://www.bancoinbursa.com",
        "DateOperationStarted": "2017-05-04",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.711456-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5500211+00:00"
    },
    {
        "COMPE": "014",
        "ISPB": "09274232",
        "Document": "09.274.232/0001-02",
        "LongName": "STATE STREET BRASIL S.A. - BANCO COMERCIAL",
        "ShortName": "STATE STREET BR S.A. BCO COMERCIAL",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2008-08-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114679-03:00",
        "DateUpdated": "2021-10-23T20:54:59.5224714-03:00"
    },
    {
        "COMPE": "015",
        "ISPB": "02819125",
        "Document": "02.819.125/0001-73",
        "LongName": "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
        "ShortName": "UBS BRASIL CCTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-03-15",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114483-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114485-03:00"
    },
    {
        "COMPE": "016",
        "ISPB": "04715685",
        "Document": "04.715.685/0001-03",
        "LongName": "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
        "ShortName": "CCM DESP TRÂNS SC E RS",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2012-05-08",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114553-03:00",
        "DateUpdated": "2022-06-23T13:51:34.9570486+00:00"
    },
    {
        "COMPE": "017",
        "ISPB": "42272526",
        "Document": "42.272.526/0001-70",
        "LongName": "BNY Mellon Banco S.A.",
        "ShortName": "BNY MELLON BCO S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bnymellon.com.br",
        "DateOperationStarted": "2012-11-19",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115161-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4024695+00:00"
    },
    {
        "COMPE": "018",
        "ISPB": "57839805",
        "Document": "57.839.805/0001-40",
        "LongName": "Banco Tricury S.A.",
        "ShortName": "BCO TRICURY S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancotricury.com.br",
        "DateOperationStarted": "2013-08-05",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115214-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4026223+00:00"
    },
    {
        "COMPE": "021",
        "ISPB": "28127603",
        "Document": "28.127.603/0001-78",
        "LongName": "BANESTES S.A. Banco do Estado do Espírito Santo",
        "ShortName": "BCO BANESTES S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.banestes.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114987-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493378+00:00"
    },
    {
        "COMPE": "024",
        "ISPB": "10866788",
        "Document": "10.866.788/0001-77",
        "LongName": "Banco Bandepe S.A.",
        "ShortName": "BCO BANDEPE S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.santander.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114722-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114724-03:00"
    },
    {
        "COMPE": "025",
        "ISPB": "03323840",
        "Document": "03.323.840/0001-83",
        "LongName": "Banco Alfa S.A.",
        "ShortName": "BCO ALFA S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.bancoalfa.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114509-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477558+00:00"
    },
    {
        "COMPE": "029",
        "ISPB": "33885724",
        "Document": "33.885.724/0001-19",
        "LongName": "Banco Itaú Consignado S.A.",
        "ShortName": "BANCO ITAÚ CONSIGNADO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.itau.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115099-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5500445+00:00"
    },
    {
        "COMPE": "033",
        "ISPB": "90400888",
        "Document": "90.400.888/0001-42",
        "LongName": "BANCO SANTANDER (BRASIL) S.A.",
        "ShortName": "BCO SANTANDER (BRASIL) S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.santander.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115471-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4032283+00:00"
    },
    {
        "COMPE": "036",
        "ISPB": "06271464",
        "Document": "06.271.464/0001-19",
        "LongName": "Banco Bradesco BBI S.A.",
        "ShortName": "BCO BBI S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bradescobbi.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114605-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114607-03:00"
    },
    {
        "COMPE": "037",
        "ISPB": "04913711",
        "Document": "04.913.711/0001-08",
        "LongName": "Banco do Estado do Pará S.A.",
        "ShortName": "BCO DO EST. DO PA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário e Pessoal"
        ],
        "Url": "https://www.banpara.b.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114571-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4005858+00:00"
    },
    {
        "COMPE": "040",
        "ISPB": "03609817",
        "Document": "03.609.817/0001-50",
        "LongName": "Banco Cargill S.A.",
        "ShortName": "BCO CARGILL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancocargill.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711452-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114522-03:00"
    },
    {
        "COMPE": "041",
        "ISPB": "92702067",
        "Document": "92.702.067/0001-96",
        "LongName": "Banco do Estado do Rio Grande do Sul S.A.",
        "ShortName": "BCO DO ESTADO DO RS S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.banrisul.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115478-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493547+00:00"
    },
    {
        "COMPE": "047",
        "ISPB": "13009717",
        "Document": "13.009.717/0001-46",
        "LongName": "Banco do Estado de Sergipe S.A.",
        "ShortName": "BCO DO EST. DE SE S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.banese.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114799-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493295+00:00"
    },
    {
        "COMPE": "060",
        "ISPB": "04913129",
        "Document": "04.913.129/0001-41",
        "LongName": "Confidence Corretora de Câmbio S.A.",
        "ShortName": "CONFIDENCE CC S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-09-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114567-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114569-03:00"
    },
    {
        "COMPE": "062",
        "ISPB": "03012230",
        "Document": "03.012.230/0001-69",
        "LongName": "Hipercard Banco Múltiplo S.A.",
        "ShortName": "HIPERCARD BM S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.hipercard.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114491-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114492-03:00"
    },
    {
        "COMPE": "063",
        "ISPB": "04184779",
        "Document": "04.184.779/0001-01",
        "LongName": "Banco Bradescard S.A.",
        "ShortName": "BANCO BRADESCARD",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Troca de Modalidade"
        ],
        "Url": "https://www.ibi.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114534-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477881+00:00"
    },
    {
        "COMPE": "064",
        "ISPB": "04332281",
        "Document": "04.332.281/0001-30",
        "LongName": "Goldman Sachs do Brasil Banco Múltiplo S.A.",
        "ShortName": "GOLDMAN SACHS DO BRASIL BM S.A",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.goldmansachs.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114545-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114547-03:00"
    },
    {
        "COMPE": "065",
        "ISPB": "48795256",
        "Document": "48.795.256/0001-69",
        "LongName": "Banco AndBank (Brasil) S.A.",
        "ShortName": "BCO ANDBANK S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.andbank-lla.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711518-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4025314+00:00"
    },
    {
        "COMPE": "066",
        "ISPB": "02801938",
        "Document": "02.801.938/0001-36",
        "LongName": "BANCO MORGAN STANLEY S.A.",
        "ShortName": "BCO MORGAN STANLEY S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.morganstanley.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711448-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114481-03:00"
    },
    {
        "COMPE": "069",
        "ISPB": "61033106",
        "Document": "61.033.106/0001-86",
        "LongName": "Banco Crefisa S.A.",
        "ShortName": "BCO CREFISA S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": "https://www.crefisa.com.br",
        "DateOperationStarted": "2005-11-18",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115283-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5485845+00:00"
    },
    {
        "COMPE": "070",
        "ISPB": "00000208",
        "Document": "00.000.208/0001-00",
        "LongName": "BRB - Banco de Brasília S.A.",
        "ShortName": "BRB - BCO DE BRASILIA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.brb.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7111961-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5548994+00:00"
    },
    {
        "COMPE": "074",
        "ISPB": "03017677",
        "Document": "03.017.677/0001-20",
        "LongName": "Banco J. Safra S.A.",
        "ShortName": "BCO. J.SAFRA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.safra.com.br",
        "DateOperationStarted": "2004-08-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114494-03:00",
        "DateUpdated": "2022-06-23T13:48:15.349196+00:00"
    },
    {
        "COMPE": "075",
        "ISPB": "03532415",
        "Document": "03.532.415/0001-02",
        "LongName": "Banco ABN Amro S.A.",
        "ShortName": "BCO ABN AMRO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.abnamro.com",
        "DateOperationStarted": "2005-11-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114516-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114518-03:00"
    },
    {
        "COMPE": "076",
        "ISPB": "07656500",
        "Document": "07.656.500/0001-25",
        "LongName": "Banco KDB do Brasil S.A.",
        "ShortName": "BCO KDB BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancokdb.com.br/home.html",
        "DateOperationStarted": "2006-04-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114628-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114629-03:00"
    },
    {
        "COMPE": "077",
        "ISPB": "00416968",
        "Document": "00.416.968/0001-01",
        "LongName": "Banco Inter S.A.",
        "ShortName": "BANCO INTER",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Troca de Modalidade"
        ],
        "Url": "https://www.bancointer.com.br",
        "DateOperationStarted": "2008-08-15",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7112852-03:00",
        "DateUpdated": "2022-07-22T07:49:18.3999887+00:00"
    },
    {
        "COMPE": "078",
        "ISPB": "34111187",
        "Document": "34.111.187/0001-12",
        "LongName": "Haitong Banco de Investimento do Brasil S.A.",
        "ShortName": "HAITONG BI DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2008-07-31",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711511-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115112-03:00"
    },
    {
        "COMPE": "079",
        "ISPB": "09516419",
        "Document": "09.516.419/0001-75",
        "LongName": "PICPAY BANK - BANCO MÚLTIPLO S.A",
        "ShortName": "PICPAY BANK - BANCO MÚLTIPLO S.A",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancooriginal.com.br",
        "DateOperationStarted": "2008-07-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114694-03:00",
        "DateUpdated": "2022-07-07T12:06:14.8083896+00:00"
    },
    {
        "COMPE": "080",
        "ISPB": "73622748",
        "Document": "73.622.748/0001-08",
        "LongName": "B&T CORRETORA DE CAMBIO LTDA.",
        "ShortName": "B&T CC LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-11-18",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115427-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115429-03:00"
    },
    {
        "COMPE": "081",
        "ISPB": "10264663",
        "Document": "10.264.663/0001-77",
        "LongName": "BancoSeguro S.A.",
        "ShortName": "BANCOSEGURO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": "https://www.rendimento.com.br",
        "DateOperationStarted": "2008-10-24",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114701-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5541944+00:00"
    },
    {
        "COMPE": "082",
        "ISPB": "07679404",
        "Document": "07.679.404/0001-00",
        "LongName": "BANCO TOPÁZIO S.A.",
        "ShortName": "BANCO TOPÁZIO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": "https://www.bancotopazio.com.br",
        "DateOperationStarted": "2009-06-12",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114631-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528824+00:00"
    },
    {
        "COMPE": "083",
        "ISPB": "10690848",
        "Document": "10.690.848/0001-43",
        "LongName": "Banco da China Brasil S.A.",
        "ShortName": "BCO DA CHINA BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.boc-brazil.com",
        "DateOperationStarted": "2009-07-14",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114715-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3451705+00:00"
    },
    {
        "COMPE": "084",
        "ISPB": "02398976",
        "Document": "02.398.976/0001-90",
        "LongName": "UNIPRIME DO BRASIL - COOPERATIVA DE CRÉDITO",
        "ShortName": "UNIPRIME DO BRASIL - COOP",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário Pessoal e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2009-11-24",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114472-03:00",
        "DateUpdated": "2022-08-05T12:06:01.5903749+00:00"
    },
    {
        "COMPE": "085",
        "ISPB": "05463212",
        "Document": "05.463.212/0001-29",
        "LongName": "Cooperativa Central de Crédito - Ailos",
        "ShortName": "COOPCENTRAL AILOS",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2010-05-17",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114586-03:00",
        "DateUpdated": "2022-06-23T13:51:34.7043191+00:00"
    },
    {
        "COMPE": "088",
        "ISPB": "11476673",
        "Document": "11.476.673/0001-39",
        "LongName": "BANCO RANDON S.A.",
        "ShortName": "BANCO RANDON S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancorandon.com",
        "DateOperationStarted": "2020-08-03",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114729-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114731-03:00"
    },
    {
        "COMPE": "089",
        "ISPB": "62109566",
        "Document": "62.109.566/0001-03",
        "LongName": "CREDISAN COOPERATIVA DE CRÉDITO",
        "ShortName": "CREDISAN CC",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-08-30",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115328-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4029276+00:00"
    },
    {
        "COMPE": "091",
        "ISPB": "01634601",
        "Document": "01.634.601/0001-19",
        "LongName": "CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S",
        "ShortName": "CCCM UNICRED CENTRAL RS",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-11-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114439-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114441-03:00"
    },
    {
        "COMPE": "092",
        "ISPB": "12865507",
        "Document": "12.865.507/0001-97",
        "LongName": "BRK S.A. Crédito, Financiamento e Investimento",
        "ShortName": "BRK S.A. CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-07-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114795-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114797-03:00"
    },
    {
        "COMPE": "093",
        "ISPB": "07945233",
        "Document": "07.945.233/0001-06",
        "LongName": "PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT",
        "ShortName": "POLOCRED SCMEPP LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-11-09",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114642-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3630957+00:00"
    },
    {
        "COMPE": "094",
        "ISPB": "11758741",
        "Document": "11.758.741/0001-52",
        "LongName": "Banco Finaxis S.A.",
        "ShortName": "BANCO FINAXIS",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.finaxis.com.br",
        "DateOperationStarted": "2010-08-23",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114788-03:00",
        "DateUpdated": "2022-06-23T13:48:15.347466+00:00"
    },
    {
        "COMPE": "095",
        "ISPB": "11703662",
        "Document": "11.703.662/0001-44",
        "LongName": "Travelex Banco de Câmbio S.A.",
        "ShortName": "TRAVELEX BANCO DE CÂMBIO S.A.",
        "Network": "RSFN",
        "Type": "Banco de Câmbio",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancoconfidence.com.br",
        "DateOperationStarted": "2010-06-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114742-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114786-03:00"
    },
    {
        "COMPE": "096",
        "ISPB": "00997185",
        "Document": "00.997.185/0001-50",
        "LongName": "Banco B3 S.A.",
        "ShortName": "BCO B3 S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bmfbovespa.com.br/bancobmfbovespa/",
        "DateOperationStarted": "2004-11-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711375-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3413284+00:00"
    },
    {
        "COMPE": "097",
        "ISPB": "04632856",
        "Document": "04.632.856/0001-30",
        "LongName": "Credisis - Central de Cooperativas de Crédito Ltda.",
        "ShortName": "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-02-08",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114549-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4004962+00:00"
    },
    {
        "COMPE": "098",
        "ISPB": "78157146",
        "Document": "78.157.146/0001-32",
        "LongName": "Credialiança Cooperativa de Crédito Rural",
        "ShortName": "CREDIALIANÇA CCR",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-01-18",
        "DatePixStarted": "2021-07-12 08:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115449-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3594388+00:00"
    },
    {
        "COMPE": "099",
        "ISPB": "03046391",
        "Document": "03.046.391/0001-73",
        "LongName": "UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO",
        "ShortName": "UNIPRIME COOPCENTRAL LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": null,
        "DateOperationStarted": "2011-01-11",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114498-03:00",
        "DateUpdated": "2022-08-30T12:06:59.6024775+00:00"
    },
    {
        "COMPE": "100",
        "ISPB": "00806535",
        "Document": "00.806.535/0001-54",
        "LongName": "Planner Corretora de Valores S.A.",
        "ShortName": "PLANNER CV S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-04-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7113602-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7113681-03:00"
    },
    {
        "COMPE": "101",
        "ISPB": "62287735",
        "Document": "62.287.735/0001-03",
        "LongName": "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "RENASCENCA DTVM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711535-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115352-03:00"
    },
    {
        "COMPE": "102",
        "ISPB": "02332886",
        "Document": "02.332.886/0001-04",
        "LongName": "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
        "ShortName": "XP INVESTIMENTOS CCTVM S/A",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-11-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114468-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711447-03:00"
    },
    {
        "COMPE": "104",
        "ISPB": "00360305",
        "Document": "00.360.305/0001-04",
        "LongName": "Caixa Econômica Federal",
        "ShortName": "CAIXA ECONOMICA FEDERAL",
        "Network": "RSFN",
        "Type": "Caixa Econômica Federal",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiário",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.caixa.gov.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7112717-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5559094+00:00"
    },
    {
        "COMPE": "105",
        "ISPB": "07652226",
        "Document": "07.652.226/0001-16",
        "LongName": "Lecca Crédito, Financiamento e Investimento S/A",
        "ShortName": "LECCA CFI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": null,
        "DateOperationStarted": "2011-12-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114624-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2234528+00:00"
    },
    {
        "COMPE": "107",
        "ISPB": "15114366",
        "Document": "15.114.366/0001-69",
        "LongName": "Banco Bocom BBM S.A.",
        "ShortName": "BCO BOCOM BBM S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancobbm.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114855-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3411358+00:00"
    },
    {
        "COMPE": "108",
        "ISPB": "01800019",
        "Document": "01.800.019/0001-85",
        "LongName": "PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "PORTOCRED S.A. - CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2011-11-03",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711445-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2273369+00:00"
    },
    {
        "COMPE": "111",
        "ISPB": "36113876",
        "Document": "36.113.876/0001-91",
        "LongName": "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.",
        "ShortName": "OLIVEIRA TRUST DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-03-24",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115125-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115126-03:00"
    },
    {
        "COMPE": "113",
        "ISPB": "61723847",
        "Document": "61.723.847/0001-99",
        "LongName": "NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "NEON CTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-11-28",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711531-03:00",
        "DateUpdated": "2022-03-16T07:58:39.2787909+00:00"
    },
    {
        "COMPE": "114",
        "ISPB": "05790149",
        "Document": "05.790.149/0001-35",
        "LongName": "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP",
        "ShortName": "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2011-10-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114598-03:00",
        "DateUpdated": "2022-06-23T13:51:34.6974799+00:00"
    },
    {
        "COMPE": "117",
        "ISPB": "92856905",
        "Document": "92.856.905/0001-86",
        "LongName": "ADVANCED CORRETORA DE CÂMBIO LTDA",
        "ShortName": "ADVANCED CC LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2011-10-03",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115481-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115483-03:00"
    },
    {
        "COMPE": "119",
        "ISPB": "13720915",
        "Document": "13.720.915/0001-13",
        "LongName": "Banco Western Union do Brasil S.A.",
        "ShortName": "BCO WESTERN UNION",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancowesternunion.com.br",
        "DateOperationStarted": "2011-10-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114833-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3545616+00:00"
    },
    {
        "COMPE": "120",
        "ISPB": "33603457",
        "Document": "33.603.457/0001-40",
        "LongName": "BANCO RODOBENS S.A.",
        "ShortName": "BCO RODOBENS S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Troca de Modalidade"
        ],
        "Url": "https://www.rodobens.com.br",
        "DateOperationStarted": "2012-02-06",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115081-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528422+00:00"
    },
    {
        "COMPE": "121",
        "ISPB": "10664513",
        "Document": "10.664.513/0001-50",
        "LongName": "Banco Agibank S.A.",
        "ShortName": "BCO AGIBANK S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": [
            "Cheque Especial e Troca de Modalidade",
            "Consignado",
            "Pessoal"
        ],
        "Url": "https://www.agibank.com.br",
        "DateOperationStarted": "2012-04-04",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114712-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477476+00:00"
    },
    {
        "COMPE": "122",
        "ISPB": "33147315",
        "Document": "33.147.315/0001-15",
        "LongName": "Banco Bradesco BERJ S.A.",
        "ShortName": "BCO BRADESCO BERJ S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://true",
        "DateOperationStarted": "2011-11-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115062-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3422902+00:00"
    },
    {
        "COMPE": "124",
        "ISPB": "15357060",
        "Document": "15.357.060/0001-33",
        "LongName": "Banco Woori Bank do Brasil S.A.",
        "ShortName": "BCO WOORI BANK DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": "https://www.wooribank.com.br",
        "DateOperationStarted": "2012-08-09",
        "DatePixStarted": "2021-04-27 09:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114862-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3693123+00:00"
    },
    {
        "COMPE": "125",
        "ISPB": "45246410",
        "Document": "45.246.410/0001-55",
        "LongName": "BANCO GENIAL S.A.",
        "ShortName": "BANCO GENIAL",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.brasilplural.com",
        "DateOperationStarted": "2012-11-19",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115172-03:00",
        "DateUpdated": "2022-06-23T13:48:15.355711+00:00"
    },
    {
        "COMPE": "126",
        "ISPB": "13220493",
        "Document": "13.220.493/0001-17",
        "LongName": "BR Partners Banco de Investimento S.A.",
        "ShortName": "BR PARTNERS BI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2013-01-04",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711481-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114812-03:00"
    },
    {
        "COMPE": "127",
        "ISPB": "09512542",
        "Document": "09.512.542/0001-18",
        "LongName": "Codepe Corretora de Valores e Câmbio S.A.",
        "ShortName": "CODEPE CVC S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2012-11-09",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711469-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114691-03:00"
    },
    {
        "COMPE": "128",
        "ISPB": "19307785",
        "Document": "19.307.785/0001-78",
        "LongName": "MS Bank S.A. Banco de Câmbio",
        "ShortName": "MS BANK S.A. BCO DE CÂMBIO",
        "Network": "RSFN",
        "Type": "Banco de Câmbio",
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.msbank.com.br",
        "DateOperationStarted": "2015-04-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114919-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3619443+00:00"
    },
    {
        "COMPE": "129",
        "ISPB": "18520834",
        "Document": "18.520.834/0001-93",
        "LongName": "UBS Brasil Banco de Investimento S.A.",
        "ShortName": "UBS BRASIL BI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.ubs.com",
        "DateOperationStarted": "2014-03-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114915-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114917-03:00"
    },
    {
        "COMPE": "130",
        "ISPB": "09313766",
        "Document": "09.313.766/0001-09",
        "LongName": "CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "CARUANA SCFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2013-08-12",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114682-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4009564+00:00"
    },
    {
        "COMPE": "131",
        "ISPB": "61747085",
        "Document": "61.747.085/0001-60",
        "LongName": "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA",
        "ShortName": "TULLETT PREBON BRASIL CVC LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2013-07-15",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115313-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115315-03:00"
    },
    {
        "COMPE": "132",
        "ISPB": "17453575",
        "Document": "17.453.575/0001-62",
        "LongName": "ICBC do Brasil Banco Múltiplo S.A.",
        "ShortName": "ICBC DO BRASIL BM S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.icbc.com.cn",
        "DateOperationStarted": "2013-09-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114895-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3609804+00:00"
    },
    {
        "COMPE": "133",
        "ISPB": "10398952",
        "Document": "10.398.952/0001-69",
        "LongName": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E",
        "ShortName": "CRESOL CONFEDERAÇÃO",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2016-05-02",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114705-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4010248+00:00"
    },
    {
        "COMPE": "134",
        "ISPB": "33862244",
        "Document": "33.862.244/0001-32",
        "LongName": "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "BGC LIQUIDEZ DTVM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2014-07-28",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115095-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115097-03:00"
    },
    {
        "COMPE": "136",
        "ISPB": "00315557",
        "Document": "00.315.557/0001-11",
        "LongName": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI",
        "ShortName": "CONF NAC COOP CENTRAIS UNICRED",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2014-07-28",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7112437-03:00",
        "DateUpdated": "2022-07-22T07:49:18.3999538+00:00"
    },
    {
        "COMPE": "138",
        "ISPB": "10853017",
        "Document": "10.853.017/0001-45",
        "LongName": "Get Money Corretora de Câmbio S.A.",
        "ShortName": "GET MONEY CC LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2014-07-14",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114719-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114721-03:00"
    },
    {
        "COMPE": "139",
        "ISPB": "55230916",
        "Document": "55.230.916/0001-20",
        "LongName": "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo",
        "ShortName": "INTESA SANPAOLO BRASIL S.A. BM",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.intesasanpaolobrasil.com.br",
        "DateOperationStarted": "2015-05-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115209-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115212-03:00"
    },
    {
        "COMPE": "140",
        "ISPB": "62169875",
        "Document": "62.169.875/0001-79",
        "LongName": "NU INVEST CORRETORA DE VALORES S.A.",
        "ShortName": "NU INVEST CORRETORA DE VALORES S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2015-05-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115335-03:00",
        "DateUpdated": "2021-09-28T05:25:17.025616-03:00"
    },
    {
        "COMPE": "142",
        "ISPB": "16944141",
        "Document": "16.944.141/0001-00",
        "LongName": "Broker Brasil Corretora de Câmbio Ltda.",
        "ShortName": "BROKER BRASIL CC LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2015-06-23",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114877-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114879-03:00"
    },
    {
        "COMPE": "143",
        "ISPB": "02992317",
        "Document": "02.992.317/0001-87",
        "LongName": "Treviso Corretora de Câmbio S.A.",
        "ShortName": "TREVISO CC S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2015-08-24",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114487-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114489-03:00"
    },
    {
        "COMPE": "144",
        "ISPB": "13059145",
        "Document": "13.059.145/0001-00",
        "LongName": "BEXS BANCO DE CÂMBIO S/A",
        "ShortName": "BEXS BCO DE CAMBIO S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bexs.com.br",
        "DateOperationStarted": "2016-05-23",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114803-03:00",
        "DateUpdated": "2021-05-21T23:01:28.1593486-03:00"
    },
    {
        "COMPE": "145",
        "ISPB": "50579044",
        "Document": "50.579.044/0001-96",
        "LongName": "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.",
        "ShortName": "LEVYCAM CCV LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2015-06-16",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115187-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115189-03:00"
    },
    {
        "COMPE": "146",
        "ISPB": "24074692",
        "Document": "24.074.692/0001-80",
        "LongName": "GUITTA CORRETORA DE CAMBIO LTDA.",
        "ShortName": "GUITTA CC LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2015-05-20",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114952-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114954-03:00"
    },
    {
        "COMPE": "149",
        "ISPB": "15581638",
        "Document": "15.581.638/0001-30",
        "LongName": "Facta Financeira S.A. - Crédito Financiamento e Investimento",
        "ShortName": "FACTA S.A. CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado"
        ],
        "Url": null,
        "DateOperationStarted": "2015-07-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114866-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2184797+00:00"
    },
    {
        "COMPE": "157",
        "ISPB": "09105360",
        "Document": "09.105.360/0001-22",
        "LongName": "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
        "ShortName": "ICAP DO BRASIL CTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2016-02-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114672-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114673-03:00"
    },
    {
        "COMPE": "159",
        "ISPB": "05442029",
        "Document": "05.442.029/0001-47",
        "LongName": "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
        "ShortName": "CASA CREDITO S.A. SCM",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-09-04",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114582-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3577168+00:00"
    },
    {
        "COMPE": "163",
        "ISPB": "23522214",
        "Document": "23.522.214/0001-23",
        "LongName": "Commerzbank Brasil S.A. - Banco Múltiplo",
        "ShortName": "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2016-08-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114945-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3582913+00:00"
    },
    {
        "COMPE": "173",
        "ISPB": "13486793",
        "Document": "13.486.793/0001-42",
        "LongName": "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
        "ShortName": "BRL TRUST DTVM SA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2016-11-28",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114826-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114828-03:00"
    },
    {
        "COMPE": "174",
        "ISPB": "43180355",
        "Document": "43.180.355/0001-12",
        "LongName": "PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "PEFISA S.A. - C.F.I.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": [
            "Pessoal e Outros Créditos"
        ],
        "Url": null,
        "DateOperationStarted": "2017-11-21",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115165-03:00",
        "DateUpdated": "2022-06-23T13:51:35.22637+00:00"
    },
    {
        "COMPE": "177",
        "ISPB": "65913436",
        "Document": "65.913.436/0001-17",
        "LongName": "Guide Investimentos S.A. Corretora de Valores",
        "ShortName": "GUIDE",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-05-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115361-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115363-03:00"
    },
    {
        "COMPE": "180",
        "ISPB": "02685483",
        "Document": "02.685.483/0001-30",
        "LongName": "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "CM CAPITAL MARKETS CCTVM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-01-20",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114476-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114478-03:00"
    },
    {
        "COMPE": "183",
        "ISPB": "09210106",
        "Document": "09.210.106/0001-94",
        "LongName": "SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P",
        "ShortName": "SOCRED SA - SCMEPP",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-06-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114675-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114677-03:00"
    },
    {
        "COMPE": "184",
        "ISPB": "17298092",
        "Document": "17.298.092/0001-30",
        "LongName": "Banco Itaú BBA S.A.",
        "ShortName": "BCO ITAÚ BBA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.itaubba.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114884-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114886-03:00"
    },
    {
        "COMPE": "188",
        "ISPB": "33775974",
        "Document": "33.775.974/0001-04",
        "LongName": "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES",
        "ShortName": "ATIVA S.A. INVESTIMENTOS CCTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-07-28",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115091-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115093-03:00"
    },
    {
        "COMPE": "189",
        "ISPB": "07512441",
        "Document": "07.512.441/0001-11",
        "LongName": "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
        "ShortName": "HS FINANCEIRA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-06-05",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711462-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114622-03:00"
    },
    {
        "COMPE": "190",
        "ISPB": "03973814",
        "Document": "03.973.814/0001-09",
        "LongName": "SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS E MUNICIPAI",
        "ShortName": "SERVICOOP",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-10-16",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114527-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3590559+00:00"
    },
    {
        "COMPE": "191",
        "ISPB": "04257795",
        "Document": "04.257.795/0001-79",
        "LongName": "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
        "ShortName": "NOVA FUTURA CTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-06-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114538-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711454-03:00"
    },
    {
        "COMPE": "194",
        "ISPB": "20155248",
        "Document": "20.155.248/0001-39",
        "LongName": "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "PARMETAL DTVM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-10-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114926-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114928-03:00"
    },
    {
        "COMPE": "195",
        "ISPB": "07799277",
        "Document": "07.799.277/0001-75",
        "LongName": "VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "VALOR SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-08-09",
        "DatePixStarted": null,
        "DateRegistered": "2021-08-24T13:06:55.8926601-03:00",
        "DateUpdated": "2021-08-24T13:06:55.8926604-03:00"
    },
    {
        "COMPE": "196",
        "ISPB": "32648370",
        "Document": "32.648.370/0001-26",
        "LongName": "FAIR CORRETORA DE CAMBIO S.A.",
        "ShortName": "FAIR CC S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-09-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115043-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115045-03:00"
    },
    {
        "COMPE": "197",
        "ISPB": "16501555",
        "Document": "16.501.555/0001-57",
        "LongName": "STONE INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "STONE IP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-10-18",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.711487-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3712081+00:00"
    },
    {
        "COMPE": "208",
        "ISPB": "30306294",
        "Document": "30.306.294/0001-45",
        "LongName": "Banco BTG Pactual S.A.",
        "ShortName": "BANCO BTG PACTUAL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Pessoal",
            "Troca de Modalidade e Capital de Giro"
        ],
        "Url": "https://www.btgpactual.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115006-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5478199+00:00"
    },
    {
        "COMPE": "212",
        "ISPB": "92894922",
        "Document": "92.894.922/0001-08",
        "LongName": "Banco Original S.A.",
        "ShortName": "BANCO ORIGINAL",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado e Imobiliário"
        ],
        "Url": "https://www.original.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115492-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5516934+00:00"
    },
    {
        "COMPE": "213",
        "ISPB": "54403563",
        "Document": "54.403.563/0001-50",
        "LongName": "Banco Arbi S.A.",
        "ShortName": "BCO ARBI S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado"
        ],
        "Url": "https://www.bancoarbi.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115206-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477639+00:00"
    },
    {
        "COMPE": "217",
        "ISPB": "91884981",
        "Document": "91.884.981/0001-32",
        "LongName": "Banco John Deere S.A.",
        "ShortName": "BANCO JOHN DEERE S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.johndeere.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115474-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3495801+00:00"
    },
    {
        "COMPE": "218",
        "ISPB": "71027866",
        "Document": "71.027.866/0001-34",
        "LongName": "Banco BS2 S.A.",
        "ShortName": "BCO BS2 S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": [
            "Consignado"
        ],
        "Url": "https://www.bs2.com/banco/",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115415-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5478121+00:00"
    },
    {
        "COMPE": "222",
        "ISPB": "75647891",
        "Document": "75.647.891/0001-71",
        "LongName": "BANCO CRÉDIT AGRICOLE BRASIL S.A.",
        "ShortName": "BCO CRÉDIT AGRICOLE BR S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.calyon.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115434-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4031082+00:00"
    },
    {
        "COMPE": "224",
        "ISPB": "58616418",
        "Document": "58.616.418/0001-08",
        "LongName": "Banco Fibra S.A.",
        "ShortName": "BCO FIBRA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Veículos"
        ],
        "Url": "https://www.bancofibra.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115225-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493824+00:00"
    },
    {
        "COMPE": "233",
        "ISPB": "62421979",
        "Document": "62.421.979/0001-29",
        "LongName": "Banco Cifra S.A.",
        "ShortName": "BANCO CIFRA",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancocifra.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115358-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711536-03:00"
    },
    {
        "COMPE": "237",
        "ISPB": "60746948",
        "Document": "60.746.948/0001-12",
        "LongName": "Banco Bradesco S.A.",
        "ShortName": "BCO BRADESCO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.bradesco.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115261-03:00",
        "DateUpdated": "2022-06-23T13:51:34.547804+00:00"
    },
    {
        "COMPE": "241",
        "ISPB": "31597552",
        "Document": "31.597.552/0001-52",
        "LongName": "BANCO CLASSICO S.A.",
        "ShortName": "BCO CLASSICO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://bancoclassico.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115018-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711502-03:00"
    },
    {
        "COMPE": "243",
        "ISPB": "33923798",
        "Document": "33.923.798/0001-00",
        "LongName": "BANCO MASTER S/A",
        "ShortName": "BANCO MASTER",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.bancomaxima.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2021-05-12 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115103-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4020827+00:00"
    },
    {
        "COMPE": "246",
        "ISPB": "28195667",
        "Document": "28.195.667/0001-06",
        "LongName": "Banco ABC Brasil S.A.",
        "ShortName": "BCO ABC BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Cheques Especial",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro"
        ],
        "Url": "https://www.abcbrasil.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114992-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477382+00:00"
    },
    {
        "COMPE": "249",
        "ISPB": "61182408",
        "Document": "61.182.408/0001-16",
        "LongName": "Banco Investcred Unibanco S.A.",
        "ShortName": "BANCO INVESTCRED UNIBANCO S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.itau.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711529-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115292-03:00"
    },
    {
        "COMPE": "250",
        "ISPB": "50585090",
        "Document": "50.585.090/0001-06",
        "LongName": "BCV - BANCO DE CRÉDITO E VAREJO S.A.",
        "ShortName": "\"BCV - BCO, CRÉDITO E VAREJO S.A.\"",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancobcv.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115191-03:00",
        "DateUpdated": "2022-07-22T07:49:18.402562+00:00"
    },
    {
        "COMPE": "253",
        "ISPB": "52937216",
        "Document": "52.937.216/0001-81",
        "LongName": "Bexs Corretora de Câmbio S/A",
        "ShortName": "BEXS CC S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2016-11-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115198-03:00",
        "DateUpdated": "2021-05-05T09:11:12.71152-03:00"
    },
    {
        "COMPE": "254",
        "ISPB": "14388334",
        "Document": "14.388.334/0001-99",
        "LongName": "PARANÁ BANCO S.A.",
        "ShortName": "PARANA BCO S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Troca de Modalidade"
        ],
        "Url": "https://www.paranabanco.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114844-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2263568+00:00"
    },
    {
        "COMPE": "259",
        "ISPB": "08609934",
        "Document": "08.609.934/0001-37",
        "LongName": "MONEYCORP BANCO DE CÂMBIO S.A.",
        "ShortName": "MONEYCORP BCO DE CÂMBIO S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-01-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114661-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114663-03:00"
    },
    {
        "COMPE": "260",
        "ISPB": "18236120",
        "Document": "18.236.120/0001-58",
        "LongName": "NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO",
        "ShortName": "NU PAGAMENTOS - IP",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2017-10-10",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114912-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4015261+00:00"
    },
    {
        "COMPE": "265",
        "ISPB": "33644196",
        "Document": "33.644.196/0001-06",
        "LongName": "Banco Fator S.A.",
        "ShortName": "BCO FATOR S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.fator.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115084-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4020183+00:00"
    },
    {
        "COMPE": "266",
        "ISPB": "33132044",
        "Document": "33.132.044/0001-24",
        "LongName": "BANCO CEDULA S.A.",
        "ShortName": "BCO CEDULA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancocedula.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115058-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3432427+00:00"
    },
    {
        "COMPE": "268",
        "ISPB": "14511781",
        "Document": "14.511.781/0001-93",
        "LongName": "BARI COMPANHIA HIPOTECÁRIA",
        "ShortName": "BARI CIA HIPOTECÁRIA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2017-12-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114848-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5542033+00:00"
    },
    {
        "COMPE": "269",
        "ISPB": "53518684",
        "Document": "53.518.684/0001-84",
        "LongName": "BANCO HSBC S.A.",
        "ShortName": "BCO HSBC S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-02-07",
        "DatePixStarted": "2022-01-17 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115202-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3480393+00:00"
    },
    {
        "COMPE": "270",
        "ISPB": "61444949",
        "Document": "61.444.949/0001-75",
        "LongName": "SAGITUR CORRETORA DE CÂMBIO S.A.",
        "ShortName": "SAGITUR CC",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-08-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115302-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4028539+00:00"
    },
    {
        "COMPE": "271",
        "ISPB": "27842177",
        "Document": "27.842.177/0001-91",
        "LongName": "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
        "ShortName": "IB CCTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-02-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114984-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114985-03:00"
    },
    {
        "COMPE": "272",
        "ISPB": "00250699",
        "Document": "00.250.699/0001-48",
        "LongName": "AGK CORRETORA DE CAMBIO S.A.",
        "ShortName": "AGK CC S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-11-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7112212-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7112295-03:00"
    },
    {
        "COMPE": "273",
        "ISPB": "08253539",
        "Document": "08.253.539/0001-64",
        "LongName": "Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel",
        "ShortName": "CCR DE SÃO MIGUEL DO OESTE",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-04-24",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114649-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114651-03:00"
    },
    {
        "COMPE": "274",
        "ISPB": "11581339",
        "Document": "11.581.339/0001-45",
        "LongName": "BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.",
        "ShortName": "BMP SCMEPP LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro"
        ],
        "Url": null,
        "DateOperationStarted": "2019-09-10",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114738-03:00",
        "DateUpdated": "2022-09-27T12:08:38.4177957+00:00"
    },
    {
        "COMPE": "276",
        "ISPB": "11970623",
        "Document": "11.970.623/0001-03",
        "LongName": "BANCO SENFF S.A.",
        "ShortName": "BCO SENFF S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado"
        ],
        "Url": null,
        "DateOperationStarted": "2018-06-12",
        "DatePixStarted": "2021-04-16 09:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114792-03:00",
        "DateUpdated": "2022-07-27T12:06:28.7958806+00:00"
    },
    {
        "COMPE": "278",
        "ISPB": "27652684",
        "Document": "27.652.684/0001-62",
        "LongName": "Genial Investimentos Corretora de Valores Mobiliários S.A.",
        "ShortName": "GENIAL INVESTIMENTOS CVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-05-14",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711498-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114981-03:00"
    },
    {
        "COMPE": "279",
        "ISPB": "26563270",
        "Document": "26.563.270/0001-02",
        "LongName": "PRIMACREDI COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE",
        "ShortName": "COOP DE PRIMAVERA DO LESTE",
        "Network": "RSFN",
        "Type": null,
        "PixType": "IDRT",
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-06-05",
        "DatePixStarted": "2022-04-04 14:48:55",
        "DateRegistered": "2021-05-05T09:11:12.711496-03:00",
        "DateUpdated": "2022-09-06T12:06:45.251499+00:00"
    },
    {
        "COMPE": "280",
        "ISPB": "23862762",
        "Document": "23.862.762/0001-00",
        "LongName": "WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "WILL FINANCEIRA S.A.CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-05-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114949-03:00",
        "DateUpdated": "2022-07-22T07:49:18.401656+00:00"
    },
    {
        "COMPE": "281",
        "ISPB": "76461557",
        "Document": "76.461.557/0001-91",
        "LongName": "Cooperativa de Crédito Rural Coopavel",
        "ShortName": "CCR COOPAVEL",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-11-05",
        "DatePixStarted": "2021-06-10 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115437-03:00",
        "DateUpdated": "2022-06-23T13:48:15.360395+00:00"
    },
    {
        "COMPE": "283",
        "ISPB": "89960090",
        "Document": "89.960.090/0001-76",
        "LongName": "RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA",
        "ShortName": "RB INVESTIMENTOS DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-07-05",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115467-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115469-03:00"
    },
    {
        "COMPE": "285",
        "ISPB": "71677850",
        "Document": "71.677.850/0001-77",
        "LongName": "Frente Corretora de Câmbio Ltda.",
        "ShortName": "FRENTE CC LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-09-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115423-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115425-03:00"
    },
    {
        "COMPE": "286",
        "ISPB": "07853842",
        "Document": "07.853.842/0001-35",
        "LongName": "COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO",
        "ShortName": "CCR DE OURO",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-09-17",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114638-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711464-03:00"
    },
    {
        "COMPE": "288",
        "ISPB": "62237649",
        "Document": "62.237.649/0001-88",
        "LongName": "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
        "ShortName": "CAROL DTVM LTDA.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-07-05",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115342-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115344-03:00"
    },
    {
        "COMPE": "289",
        "ISPB": "94968518",
        "Document": "94.968.518/0001-67",
        "LongName": "EFX CORRETORA DE CÂMBIO LTDA.",
        "ShortName": "EFX CC LTDA.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-03-14",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115496-03:00",
        "DateUpdated": "2022-03-16T07:58:39.2793254+00:00"
    },
    {
        "COMPE": "290",
        "ISPB": "08561701",
        "Document": "08.561.701/0001-01",
        "LongName": "PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "PAGSEGURO INTERNET IP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-11-07",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114657-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3711849+00:00"
    },
    {
        "COMPE": "292",
        "ISPB": "28650236",
        "Document": "28.650.236/0001-92",
        "LongName": "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
        "ShortName": "BS2 DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-10-24",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114995-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114997-03:00"
    },
    {
        "COMPE": "293",
        "ISPB": "71590442",
        "Document": "71.590.442/0001-83",
        "LongName": "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
        "ShortName": "LASTRO RDV DTVM LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-09-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115419-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115421-03:00"
    },
    {
        "COMPE": "296",
        "ISPB": "04062902",
        "Document": "04.062.902/0001-03",
        "LongName": "OZ CORRETORA DE CÂMBIO S.A.",
        "ShortName": "OZ CORRETORA DE CÂMBIO S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-02-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114531-03:00",
        "DateUpdated": "2022-06-23T13:48:13.517057+00:00"
    },
    {
        "COMPE": "298",
        "ISPB": "17772370",
        "Document": "17.772.370/0001-40",
        "LongName": "Vip's Corretora de Câmbio Ltda.",
        "ShortName": "VIPS CC LTDA.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-08-15",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114899-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114901-03:00"
    },
    {
        "COMPE": "299",
        "ISPB": "04814563",
        "Document": "04.814.563/0001-74",
        "LongName": "BANCO SOROCRED S.A. - BANCO MÚLTIPLO",
        "ShortName": "BCO SOROCRED S.A. - BM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2018-12-03",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114556-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4005228+00:00"
    },
    {
        "COMPE": "300",
        "ISPB": "33042151",
        "Document": "33.042.151/0001-61",
        "LongName": "Banco de la Nacion Argentina",
        "ShortName": "BCO LA NACION ARGENTINA",
        "Network": "RSFN",
        "Type": "Banco Comercial Estrangeiro - Filial no país",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bna.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115051-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3457429+00:00"
    },
    {
        "COMPE": "301",
        "ISPB": "13370835",
        "Document": "13.370.835/0001-85",
        "LongName": "DOCK INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "DOCK IP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-09-26",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114822-03:00",
        "DateUpdated": "2022-09-29T12:08:26.1905846+00:00"
    },
    {
        "COMPE": "306",
        "ISPB": "40303299",
        "Document": "40.303.299/0001-78",
        "LongName": "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
        "ShortName": "PORTOPAR DTVM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-02-06",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115158-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711516-03:00"
    },
    {
        "COMPE": "307",
        "ISPB": "03751794",
        "Document": "03.751.794/0001-13",
        "LongName": "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
        "ShortName": "TERRA INVESTIMENTOS DTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-10-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114523-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114525-03:00"
    },
    {
        "COMPE": "309",
        "ISPB": "14190547",
        "Document": "14.190.547/0001-01",
        "LongName": "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
        "ShortName": "CAMBIONET CC LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-11-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114841-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114842-03:00"
    },
    {
        "COMPE": "310",
        "ISPB": "22610500",
        "Document": "22.610.500/0001-88",
        "LongName": "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
        "ShortName": "VORTX DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2018-10-18",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114937-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114939-03:00"
    },
    {
        "COMPE": "311",
        "ISPB": "76641497",
        "Document": "76.641.497/0001-99",
        "LongName": "DOURADA CORRETORA DE CÂMBIO LTDA.",
        "ShortName": "DOURADA CORRETORA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-01-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115445-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115447-03:00"
    },
    {
        "COMPE": "312",
        "ISPB": "07693858",
        "Document": "07.693.858/0001-28",
        "LongName": "HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT",
        "ShortName": "HSCM SCMEPP LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-02-09",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114635-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114636-03:00"
    },
    {
        "COMPE": "313",
        "ISPB": "16927221",
        "Document": "16.927.221/0001-40",
        "LongName": "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
        "ShortName": "AMAZÔNIA CC LTDA.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-08-28",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114874-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114875-03:00"
    },
    {
        "COMPE": "315",
        "ISPB": "03502968",
        "Document": "03.502.968/0001-04",
        "LongName": "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
        "ShortName": "PI DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-01-24",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114512-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114514-03:00"
    },
    {
        "COMPE": "318",
        "ISPB": "61186680",
        "Document": "61.186.680/0001-74",
        "LongName": "Banco BMG S.A.",
        "ShortName": "BCO BMG S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Troca de Modalidade"
        ],
        "Url": "https://www.bancobmg.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115294-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477799+00:00"
    },
    {
        "COMPE": "319",
        "ISPB": "11495073",
        "Document": "11.495.073/0001-18",
        "LongName": "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "OM DTVM LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-03-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114734-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114735-03:00"
    },
    {
        "COMPE": "320",
        "ISPB": "07450604",
        "Document": "07.450.604/0001-89",
        "LongName": "China Construction Bank (Brasil) Banco Múltiplo S/A",
        "ShortName": "BCO CCB BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.br.ccb.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114616-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4007514+00:00"
    },
    {
        "COMPE": "321",
        "ISPB": "18188384",
        "Document": "18.188.384/0001-83",
        "LongName": "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
        "ShortName": "CREFAZ SCMEPP LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-06-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114908-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711491-03:00"
    },
    {
        "COMPE": "322",
        "ISPB": "01073966",
        "Document": "01.073.966/0001-11",
        "LongName": "Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz",
        "ShortName": "CCR DE ABELARDO LUZ",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-06-14",
        "DatePixStarted": "2022-03-14 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114423-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3602012+00:00"
    },
    {
        "COMPE": "323",
        "ISPB": "10573521",
        "Document": "10.573.521/0001-91",
        "LongName": "MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA.",
        "ShortName": "MERCADO PAGO IP LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-06-24",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114708-03:00",
        "DateUpdated": "2022-06-23T13:48:15.370529+00:00"
    },
    {
        "COMPE": "324",
        "ISPB": "21332862",
        "Document": "21.332.862/0001-91",
        "LongName": "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "CARTOS SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Troca de Modalidade."
        ],
        "Url": null,
        "DateOperationStarted": "2020-09-16",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114933-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5559184+00:00"
    },
    {
        "COMPE": "325",
        "ISPB": "13293225",
        "Document": "13.293.225/0001-25",
        "LongName": "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
        "ShortName": "ÓRAMA DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-05-31",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114815-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114817-03:00"
    },
    {
        "COMPE": "326",
        "ISPB": "03311443",
        "Document": "03.311.443/0001-91",
        "LongName": "PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
        "ShortName": "PARATI - CFI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": null,
        "DateOperationStarted": "2020-01-07",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114505-03:00",
        "DateUpdated": "2022-06-23T13:51:35.226364+00:00"
    },
    {
        "COMPE": "328",
        "ISPB": "05841967",
        "Document": "05.841.967/0001-10",
        "LongName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA",
        "ShortName": "CECM FABRIC CALÇADOS SAPIRANGA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-03-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114602-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114603-03:00"
    },
    {
        "COMPE": "329",
        "ISPB": "32402502",
        "Document": "32.402.502/0001-35",
        "LongName": "QI Sociedade de Crédito Direto S.A.",
        "ShortName": "QI SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": null,
        "DateOperationStarted": "2019-04-30",
        "DatePixStarted": "2021-06-14 09:00:00",
        "DateRegistered": "2021-05-05T09:11:12.711504-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2273481+00:00"
    },
    {
        "COMPE": "330",
        "ISPB": "00556603",
        "Document": "00.556.603/0001-74",
        "LongName": "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
        "ShortName": "BANCO BARI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Troca de Modalidade"
        ],
        "Url": null,
        "DateOperationStarted": "2019-07-22",
        "DatePixStarted": "2021-02-25 09:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7113173-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5477718+00:00"
    },
    {
        "COMPE": "331",
        "ISPB": "13673855",
        "Document": "13.673.855/0001-25",
        "LongName": "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
        "ShortName": "FRAM CAPITAL DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-09-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114829-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114831-03:00"
    },
    {
        "COMPE": "332",
        "ISPB": "13140088",
        "Document": "13.140.088/0001-99",
        "LongName": "Acesso Soluções de Pagamento S.A.",
        "ShortName": "ACESSO SOLUCOES PAGAMENTO SA",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-08-16",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114807-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3690567+00:00"
    },
    {
        "COMPE": "335",
        "ISPB": "27098060",
        "Document": "27.098.060/0001-45",
        "LongName": "Banco Digio S.A.",
        "ShortName": "BANCO DIGIO",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": "Destinatário",
        "Products": [
            "Consignado"
        ],
        "Url": "https://www.digio.com.br",
        "DateOperationStarted": "2019-11-25",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114964-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493125+00:00"
    },
    {
        "COMPE": "336",
        "ISPB": "31872495",
        "Document": "31.872.495/0001-72",
        "LongName": "Banco C6 S.A.",
        "ShortName": "BCO C6 S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro"
        ],
        "Url": "https://www.c6bank.com",
        "DateOperationStarted": "2019-01-31",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115026-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5478356+00:00"
    },
    {
        "COMPE": "340",
        "ISPB": "09554480",
        "Document": "09.554.480/0001-07",
        "LongName": "SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "SUPERDIGITAL I.P. S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-09-25",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114697-03:00",
        "DateUpdated": "2022-07-07T12:06:14.8084022+00:00"
    },
    {
        "COMPE": "341",
        "ISPB": "60701190",
        "Document": "60.701.190/0001-04",
        "LongName": "ITAÚ UNIBANCO S.A.",
        "ShortName": "ITAÚ UNIBANCO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.itau.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115257-03:00",
        "DateUpdated": "2022-07-22T07:49:18.402737+00:00"
    },
    {
        "COMPE": "342",
        "ISPB": "32997490",
        "Document": "32.997.490/0001-39",
        "LongName": "Creditas Sociedade de Crédito Direto S.A.",
        "ShortName": "CREDITAS SCD",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-07-26",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115047-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115049-03:00"
    },
    {
        "COMPE": "343",
        "ISPB": "24537861",
        "Document": "24.537.861/0001-71",
        "LongName": "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
        "ShortName": "FFA SCMEPP LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-11-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114956-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114958-03:00"
    },
    {
        "COMPE": "348",
        "ISPB": "33264668",
        "Document": "33.264.668/0001-03",
        "LongName": "Banco XP S.A.",
        "ShortName": "BCO XP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Pessoal",
            "Troca de Modalidade e Capital de Giro"
        ],
        "Url": null,
        "DateOperationStarted": "2019-11-01",
        "DatePixStarted": "2021-06-01 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115069-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5535199+00:00"
    },
    {
        "COMPE": "349",
        "ISPB": "27214112",
        "Document": "27.214.112/0001-00",
        "LongName": "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "AL5 S.A. CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Outros",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2019-10-24",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114967-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5466857+00:00"
    },
    {
        "COMPE": "350",
        "ISPB": "01330387",
        "Document": "01.330.387/0001-07",
        "LongName": "COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE",
        "ShortName": "CREHNOR LARANJEIRAS",
        "Network": "Internet",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-05-27",
        "DatePixStarted": "2022-01-07 08:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114431-03:00",
        "DateUpdated": "2022-01-05T11:00:32.7656692+00:00"
    },
    {
        "COMPE": "352",
        "ISPB": "29162769",
        "Document": "29.162.769/0001-98",
        "LongName": "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "TORO CTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-11-29",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115003-03:00",
        "DateUpdated": "2021-09-13T19:08:15.553781-03:00"
    },
    {
        "COMPE": "354",
        "ISPB": "52904364",
        "Document": "52.904.364/0001-08",
        "LongName": "NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
        "ShortName": "NECTON INVESTIMENTOS S.A CVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-10-30",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115194-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115196-03:00"
    },
    {
        "COMPE": "355",
        "ISPB": "34335592",
        "Document": "34.335.592/0001-14",
        "LongName": "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "ÓTIMO SCD S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2019-10-21",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115114-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115115-03:00"
    },
    {
        "COMPE": "358",
        "ISPB": "09464032",
        "Document": "09.464.032/0001-12",
        "LongName": "MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "MIDWAY S.A. - SCFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Pessoal"
        ],
        "Url": null,
        "DateOperationStarted": "2021-02-01",
        "DatePixStarted": "2021-07-19 15:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114686-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2241378+00:00"
    },
    {
        "COMPE": "359",
        "ISPB": "05351887",
        "Document": "05.351.887/0001-86",
        "LongName": "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
        "ShortName": "ZEMA CFI S/A",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veiculos"
        ],
        "Url": null,
        "DateOperationStarted": "2020-06-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114578-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2350114+00:00"
    },
    {
        "COMPE": "360",
        "ISPB": "02276653",
        "Document": "02.276.653/0001-23",
        "LongName": "TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "TRINUS CAPITAL DTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-07-16",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114461-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114462-03:00"
    },
    {
        "COMPE": "362",
        "ISPB": "01027058",
        "Document": "01.027.058/0001-91",
        "LongName": "CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO",
        "ShortName": "CIELO IP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-07-17",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.711436-03:00",
        "DateUpdated": "2022-09-27T12:08:38.4165642+00:00"
    },
    {
        "COMPE": "363",
        "ISPB": "62285390",
        "Document": "62.285.390/0001-40",
        "LongName": "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "SINGULARE CTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-06-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115346-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115348-03:00"
    },
    {
        "COMPE": "364",
        "ISPB": "09089356",
        "Document": "09.089.356/0001-18",
        "LongName": "GERENCIANET S.A.",
        "ShortName": "GERENCIANET",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-03-16",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114668-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3607764+00:00"
    },
    {
        "COMPE": "365",
        "ISPB": "68757681",
        "Document": "68.757.681/0001-70",
        "LongName": "SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.",
        "ShortName": "SIMPAUL",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-03-17",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115408-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711541-03:00"
    },
    {
        "COMPE": "366",
        "ISPB": "61533584",
        "Document": "61.533.584/0001-55",
        "LongName": "BANCO SOCIETE GENERALE BRASIL S.A.",
        "ShortName": "BCO SOCIETE GENERALE BRASIL",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.sgbrasil.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115306-03:00",
        "DateUpdated": "2022-06-23T13:48:15.352835+00:00"
    },
    {
        "COMPE": "367",
        "ISPB": "34711571",
        "Document": "34.711.571/0001-56",
        "LongName": "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "VITREO DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-03-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115117-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115119-03:00"
    },
    {
        "COMPE": "368",
        "ISPB": "08357240",
        "Document": "08.357.240/0001-50",
        "LongName": "Banco CSF S.A.",
        "ShortName": "BCO CSF S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.carrefoursolucoes.com.br",
        "DateOperationStarted": "2020-05-25",
        "DatePixStarted": "2022-10-31 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114653-03:00",
        "DateUpdated": "2022-08-24T12:36:19.0352572+00:00"
    },
    {
        "COMPE": "370",
        "ISPB": "61088183",
        "Document": "61.088.183/0001-33",
        "LongName": "Banco Mizuho do Brasil S.A.",
        "ShortName": "BCO MIZUHO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.mizuhobank.com/brazil/pt/",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115287-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115289-03:00"
    },
    {
        "COMPE": "371",
        "ISPB": "92875780",
        "Document": "92.875.780/0001-31",
        "LongName": "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
        "ShortName": "WARREN CVMC LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-05-13",
        "DatePixStarted": "2022-11-14 12:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115489-03:00",
        "DateUpdated": "2022-09-06T12:06:46.3133882+00:00"
    },
    {
        "COMPE": "373",
        "ISPB": "35977097",
        "Document": "35.977.097/0001-71",
        "LongName": "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
        "ShortName": "UP.P SEP S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Pessoal",
            "Veiculos e Outros Créditoss"
        ],
        "Url": null,
        "DateOperationStarted": "2020-03-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115121-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2349989+00:00"
    },
    {
        "COMPE": "374",
        "ISPB": "27351731",
        "Document": "27.351.731/0001-38",
        "LongName": "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
        "ShortName": "REALIZE CFI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Pessoal e Outros Créditos"
        ],
        "Url": null,
        "DateOperationStarted": "2020-09-16",
        "DatePixStarted": "2021-04-14 12:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114975-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2273551+00:00"
    },
    {
        "COMPE": "376",
        "ISPB": "33172537",
        "Document": "33.172.537/0001-98",
        "LongName": "BANCO J.P. MORGAN S.A.",
        "ShortName": "BCO J.P. MORGAN S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Pessoal"
        ],
        "Url": "https://www.jpmorgan.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2022-10-01 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115066-03:00",
        "DateUpdated": "2022-09-23T12:08:11.8249103+00:00"
    },
    {
        "COMPE": "377",
        "ISPB": "17826860",
        "Document": "17.826.860/0001-81",
        "LongName": "BMS SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "BMS SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-10-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114904-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114906-03:00"
    },
    {
        "COMPE": "378",
        "ISPB": "01852137",
        "Document": "01.852.137/0001-37",
        "LongName": "BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA",
        "ShortName": "BCO BRASILEIRO DE CRÉDITO S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-09-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114454-03:00",
        "DateUpdated": "2022-01-05T11:00:32.0511464+00:00"
    },
    {
        "COMPE": "379",
        "ISPB": "01658426",
        "Document": "01.658.426/0001-08",
        "LongName": "COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU",
        "ShortName": "CECM COOPERFORTE",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial e Troca de Modalidade",
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2020-09-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114443-03:00",
        "DateUpdated": "2022-06-23T13:51:35.1651622+00:00"
    },
    {
        "COMPE": "380",
        "ISPB": "22896431",
        "Document": "22.896.431/0001-10",
        "LongName": "PICPAY INSTITUIçãO DE PAGAMENTO S.A.",
        "ShortName": "PICPAY",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": "https://www.picpay.com.br",
        "DateOperationStarted": "2020-10-15",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114941-03:00",
        "DateUpdated": "2022-09-10T12:06:04.1466825+00:00"
    },
    {
        "COMPE": "381",
        "ISPB": "60814191",
        "Document": "60.814.191/0001-57",
        "LongName": "BANCO MERCEDES-BENZ DO BRASIL S.A.",
        "ShortName": "BCO MERCEDES-BENZ S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Pessoal e Veículos"
        ],
        "Url": "https://www.bancomercedesbenz.com.br",
        "DateOperationStarted": "2020-05-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115265-03:00",
        "DateUpdated": "2022-06-23T13:51:34.550966+00:00"
    },
    {
        "COMPE": "382",
        "ISPB": "04307598",
        "Document": "04.307.598/0001-17",
        "LongName": "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
        "ShortName": "FIDUCIA SCMEPP LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-07-31",
        "DatePixStarted": "2021-12-15 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114541-03:00",
        "DateUpdated": "2021-12-13T12:13:48.2864703+00:00"
    },
    {
        "COMPE": "383",
        "ISPB": "21018182",
        "Document": "21.018.182/0001-06",
        "LongName": "EBANX INSTITUICAO DE PAGAMENTOS LTDA.",
        "ShortName": "EBANX IP LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-07-09",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.711493-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3562873+00:00"
    },
    {
        "COMPE": "384",
        "ISPB": "11165756",
        "Document": "11.165.756/0001-07",
        "LongName": "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
        "ShortName": "GLOBAL SCM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-10-13",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114726-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114728-03:00"
    },
    {
        "COMPE": "385",
        "ISPB": "03844699",
        "Document": "03.844.699/0001-64",
        "LongName": "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V",
        "ShortName": "CECM DOS TRAB.PORT. DA G.VITOR",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-03-08",
        "DatePixStarted": null,
        "DateRegistered": "2022-03-16T07:58:41.1136389+00:00",
        "DateUpdated": "2022-03-16T07:58:41.1136624+00:00"
    },
    {
        "COMPE": "386",
        "ISPB": "30680829",
        "Document": "30.680.829/0001-43",
        "LongName": "NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento",
        "ShortName": "NU FINANCEIRA S.A. CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Pessoal"
        ],
        "Url": null,
        "DateOperationStarted": "2021-01-12",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711501-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2252717+00:00"
    },
    {
        "COMPE": "387",
        "ISPB": "03215790",
        "Document": "03.215.790/0001-10",
        "LongName": "Banco Toyota do Brasil S.A.",
        "ShortName": "BCO TOYOTA DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Veículos"
        ],
        "Url": "https://www.bancotoyota.com.br",
        "DateOperationStarted": "2020-07-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114501-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528899+00:00"
    },
    {
        "COMPE": "389",
        "ISPB": "17184037",
        "Document": "17.184.037/0001-10",
        "LongName": "Banco Mercantil do Brasil S.A.",
        "ShortName": "BCO MERCANTIL DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.mercantil.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114881-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5509576+00:00"
    },
    {
        "COMPE": "390",
        "ISPB": "59274605",
        "Document": "59.274.605/0001-13",
        "LongName": "BANCO GM S.A.",
        "ShortName": "BCO GM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2020-07-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115236-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5493915+00:00"
    },
    {
        "COMPE": "391",
        "ISPB": "08240446",
        "Document": "08.240.446/0001-03",
        "LongName": "COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM",
        "ShortName": "CCR DE IBIAM",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-07-10",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114646-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3693661+00:00"
    },
    {
        "COMPE": "393",
        "ISPB": "59109165",
        "Document": "59.109.165/0001-49",
        "LongName": "Banco Volkswagen S.A.",
        "ShortName": "BCO VOLKSWAGEN S.A",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.bancovw.com.br",
        "DateOperationStarted": "2020-08-17",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115229-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528975+00:00"
    },
    {
        "COMPE": "394",
        "ISPB": "07207996",
        "Document": "07.207.996/0001-50",
        "LongName": "Banco Bradesco Financiamentos S.A.",
        "ShortName": "BCO BRADESCO FINANC. S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Troca de Modalidade"
        ],
        "Url": "https://www.bradescofinanciamentos.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114609-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4007197+00:00"
    },
    {
        "COMPE": "395",
        "ISPB": "08673569",
        "Document": "08.673.569/0001-20",
        "LongName": "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "F D GOLD DTVM LTDA",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-12-01",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114664-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114666-03:00"
    },
    {
        "COMPE": "396",
        "ISPB": "13884775",
        "Document": "13.884.775/0001-19",
        "LongName": "HUB INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "HUB IP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-08-13",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114837-03:00",
        "DateUpdated": "2022-09-27T12:08:38.417997+00:00"
    },
    {
        "COMPE": "397",
        "ISPB": "34088029",
        "Document": "34.088.029/0001-99",
        "LongName": "LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
        "ShortName": "LISTO SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-10-15",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115106-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115108-03:00"
    },
    {
        "COMPE": "398",
        "ISPB": "31749596",
        "Document": "31.749.596/0001-50",
        "LongName": "IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "IDEAL CTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-01-11",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115022-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115024-03:00"
    },
    {
        "COMPE": "399",
        "ISPB": "01701201",
        "Document": "01.701.201/0001-89",
        "LongName": "Kirton Bank S.A. - Banco Múltiplo",
        "ShortName": "KIRTON BANK",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://true",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114446-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114448-03:00"
    },
    {
        "COMPE": "400",
        "ISPB": "05491616",
        "Document": "05.491.616/0001-26",
        "LongName": "COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE - CREDIT",
        "ShortName": "COOP CREDITAG",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-02-24",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711459-03:00",
        "DateUpdated": "2021-08-24T13:06:53.2851364-03:00"
    },
    {
        "COMPE": "401",
        "ISPB": "15111975",
        "Document": "15.111.975/0001-64",
        "LongName": "IUGU INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "IUGU IP S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-05-06",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114851-03:00",
        "DateUpdated": "2022-08-31T12:06:42.9463093+00:00"
    },
    {
        "COMPE": "402",
        "ISPB": "36947229",
        "Document": "36.947.229/0001-85",
        "LongName": "COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS",
        "ShortName": "COBUCCIO S.A. SCFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-12-03",
        "DatePixStarted": "2021-12-08 09:15:00",
        "DateRegistered": "2021-05-05T09:11:12.7115132-03:00",
        "DateUpdated": "2022-10-29T12:07:14.5243332+00:00"
    },
    {
        "COMPE": "403",
        "ISPB": "37880206",
        "Document": "37.880.206/0001-63",
        "LongName": "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "CORA SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "IDRT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-09-29",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115147-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115149-03:00"
    },
    {
        "COMPE": "404",
        "ISPB": "37241230",
        "Document": "37.241.230/0001-52",
        "LongName": "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "SUMUP SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-10-16",
        "DatePixStarted": "2021-04-19 09:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115136-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115138-03:00"
    },
    {
        "COMPE": "406",
        "ISPB": "37715993",
        "Document": "37.715.993/0001-98",
        "LongName": "ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "ACCREDITO SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-11-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115144-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4022529+00:00"
    },
    {
        "COMPE": "407",
        "ISPB": "00329598",
        "Document": "00.329.598/0001-67",
        "LongName": "ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "ÍNDIGO INVESTIMENTOS DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-02-22",
        "DatePixStarted": "2022-11-08 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.711258-03:00",
        "DateUpdated": "2022-10-27T12:08:54.6071326+00:00"
    },
    {
        "COMPE": "408",
        "ISPB": "36586946",
        "Document": "36.586.946/0001-29",
        "LongName": "BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "BONUSPAGO SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2020-10-14",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115128-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711513-03:00"
    },
    {
        "COMPE": "410",
        "ISPB": "05684234",
        "Document": "05.684.234/0001-19",
        "LongName": "PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "PLANNER SOCIEDADE DE CRÉDITO DIRETO",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-02-17",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114594-03:00",
        "DateUpdated": "2022-09-27T12:08:38.4172421+00:00"
    },
    {
        "COMPE": "411",
        "ISPB": "05192316",
        "Document": "05.192.316/0001-46",
        "LongName": "Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos",
        "ShortName": "VIA CERTA FINANCIADORA S.A. - CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": null,
        "DateOperationStarted": "2021-02-22",
        "DatePixStarted": "2021-06-24 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114575-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2350045+00:00"
    },
    {
        "COMPE": "412",
        "ISPB": "15173776",
        "Document": "15.173.776/0001-80",
        "LongName": "SOCIAL BANK BANCO MÚLTIPLO S/A",
        "ShortName": "SOCIAL BANK S/A",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": "https://www.bancocapital.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2022-09-01 09:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114859-03:00",
        "DateUpdated": "2022-10-27T12:08:55.982745+00:00"
    },
    {
        "COMPE": "413",
        "ISPB": "01858774",
        "Document": "01.858.774/0001-10",
        "LongName": "BANCO BV S.A.",
        "ShortName": "BCO BV S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-05-21",
        "DatePixStarted": "2021-06-14 08:00:00",
        "DateRegistered": "2021-05-21T23:01:29.4844407-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3691194+00:00"
    },
    {
        "COMPE": "414",
        "ISPB": "37526080",
        "Document": "37.526.080/0001-23",
        "LongName": "LEND SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "LEND SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-03-25",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711514-03:00",
        "DateUpdated": "2022-10-29T12:07:14.5244799+00:00"
    },
    {
        "COMPE": "416",
        "ISPB": "19324634",
        "Document": "19.324.634/0001-28",
        "LongName": "LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "LAMARA SCD S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-04-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114922-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114924-03:00"
    },
    {
        "COMPE": "418",
        "ISPB": "37414009",
        "Document": "37.414.009/0001-59",
        "LongName": "ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A",
        "ShortName": "ZIPDIN SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-08-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-08-24T13:06:55.8926609-03:00",
        "DateUpdated": "2021-08-24T13:06:55.8926612-03:00"
    },
    {
        "COMPE": "419",
        "ISPB": "38129006",
        "Document": "38.129.006/0001-36",
        "LongName": "NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "NUMBRS SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-03-18",
        "DatePixStarted": "2022-01-06 15:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115151-03:00",
        "DateUpdated": "2022-01-05T11:00:33.9703822+00:00"
    },
    {
        "COMPE": "421",
        "ISPB": "39343350",
        "Document": "39.343.350/0001-96",
        "LongName": "LAR COOPERATIVA DE CRÉDITO - LAR CREDI",
        "ShortName": "CC LAR CREDI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-07-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-08-02T11:56:55.6601604-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3652178+00:00"
    },
    {
        "COMPE": "422",
        "ISPB": "58160789",
        "Document": "58.160.789/0001-28",
        "LongName": "Banco Safra S.A.",
        "ShortName": "BCO SAFRA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial e Troca de Modalidade e Capital de Giro",
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos"
        ],
        "Url": "https://www.safra.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115218-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528497+00:00"
    },
    {
        "COMPE": "423",
        "ISPB": "00460065",
        "Document": "00.460.065/0001-10",
        "LongName": "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS",
        "ShortName": "COLUNA S.A. DTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-06-07",
        "DatePixStarted": null,
        "DateRegistered": "2021-06-03T10:45:43.4334262-03:00",
        "DateUpdated": "2021-06-03T10:45:43.4334304-03:00"
    },
    {
        "COMPE": "425",
        "ISPB": "03881423",
        "Document": "03.881.423/0001-56",
        "LongName": "SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "SOCINAL S.A. CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": null,
        "DateOperationStarted": "2021-07-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-08-02T11:56:55.6601087-03:00",
        "DateUpdated": "2022-07-27T12:06:28.7956742+00:00"
    },
    {
        "COMPE": "426",
        "ISPB": "11285104",
        "Document": "11.285.104/0001-06",
        "LongName": "Biorc Financeira - Crédito, Financiamento e Investimento S.A.",
        "ShortName": "BIORC FINANCEIRA - CFI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-05-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-21T23:01:29.4844658-03:00",
        "DateUpdated": "2021-05-21T23:01:29.4844662-03:00"
    },
    {
        "COMPE": "427",
        "ISPB": "27302181",
        "Document": "27.302.181/0001-67",
        "LongName": "COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO",
        "ShortName": "CRED-UFES",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Troca de Modalidade"
        ],
        "Url": null,
        "DateOperationStarted": "2021-04-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114971-03:00",
        "DateUpdated": "2022-06-23T13:51:34.9098416+00:00"
    },
    {
        "COMPE": "428",
        "ISPB": "39664698",
        "Document": "39.664.698/0001-85",
        "LongName": "CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "CREDSYSTEM SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-04-20",
        "DatePixStarted": "2022-02-14 15:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115154-03:00",
        "DateUpdated": "2022-07-19T12:06:19.3291547+00:00"
    },
    {
        "COMPE": "429",
        "ISPB": "05676026",
        "Document": "05.676.026/0001-78",
        "LongName": "Crediare S.A. - Crédito, financiamento e investimento",
        "ShortName": "CREDIARE CFI S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Outros Créditos e Troca de Modalidade",
            "Pessoal",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2021-09-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-13T19:08:16.9817149-03:00",
        "DateUpdated": "2022-06-23T13:51:35.216358+00:00"
    },
    {
        "COMPE": "430",
        "ISPB": "00204963",
        "Document": "00.204.963/0001-07",
        "LongName": "COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA",
        "ShortName": "CCR SEARA",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-08-27",
        "DatePixStarted": "2022-02-11 10:00:00",
        "DateRegistered": "2021-08-24T13:06:55.8926308-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3693815+00:00"
    },
    {
        "COMPE": "433",
        "ISPB": "44077014",
        "Document": "44.077.014/0001-89",
        "LongName": "BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "BR-CAPITAL DTVM S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-06-21",
        "DatePixStarted": null,
        "DateRegistered": "2021-06-22T10:03:37.2758829-03:00",
        "DateUpdated": "2021-06-22T10:03:37.2758835-03:00"
    },
    {
        "COMPE": "435",
        "ISPB": "38224857",
        "Document": "38.224.857/0001-68",
        "LongName": "DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "DELCRED SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-08-27",
        "DatePixStarted": "2022-08-23 10:00:00",
        "DateRegistered": "2021-08-24T13:06:55.8926615-03:00",
        "DateUpdated": "2022-08-10T12:06:36.5859495+00:00"
    },
    {
        "COMPE": "438",
        "ISPB": "67030395",
        "Document": "67.030.395/0001-46",
        "LongName": "TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "TRUSTEE DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-05-04",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115365-03:00",
        "DateUpdated": "2022-08-25T12:36:48.5303514+00:00"
    },
    {
        "COMPE": "439",
        "ISPB": "16695922",
        "Document": "16.695.922/0001-09",
        "LongName": "ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "ID CTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-30",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-28T05:25:19.3476011-03:00",
        "DateUpdated": "2021-09-28T05:25:19.3476014-03:00"
    },
    {
        "COMPE": "440",
        "ISPB": "82096447",
        "Document": "82.096.447/0001-70",
        "LongName": "CREDIBRF - COOPERATIVA DE CRÉDITO",
        "ShortName": "CREDIBRF COOP",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-02",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-13T19:08:16.9817387-03:00",
        "DateUpdated": "2021-10-23T20:54:59.5267227-03:00"
    },
    {
        "COMPE": "442",
        "ISPB": "87963450",
        "Document": "87.963.450/0001-68",
        "LongName": "MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "MAGNETIS - DTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-15",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-13T19:08:16.9817391-03:00",
        "DateUpdated": "2021-09-13T19:08:16.9817393-03:00"
    },
    {
        "COMPE": "443",
        "ISPB": "39416705",
        "Document": "39.416.705/0001-20",
        "LongName": "CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "CREDIHOME SCD",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-11-18",
        "DatePixStarted": null,
        "DateRegistered": "2021-11-22T11:00:23.679021-03:00",
        "DateUpdated": "2021-11-22T11:00:23.6790215-03:00"
    },
    {
        "COMPE": "444",
        "ISPB": "40654622",
        "Document": "40.654.622/0001-58",
        "LongName": "TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "TRINUS SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-09",
        "DatePixStarted": "2022-11-07 17:00:00",
        "DateRegistered": "2021-09-13T19:08:16.9817382-03:00",
        "DateUpdated": "2022-10-19T12:08:46.7241587+00:00"
    },
    {
        "COMPE": "445",
        "ISPB": "35551187",
        "Document": "35.551.187/0001-04",
        "LongName": "PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "PLANTAE CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-06-23",
        "DatePixStarted": null,
        "DateRegistered": "2021-06-22T10:03:37.2758379-03:00",
        "DateUpdated": "2021-06-22T10:03:37.2758811-03:00"
    },
    {
        "COMPE": "447",
        "ISPB": "12392983",
        "Document": "12.392.983/0001-38",
        "LongName": "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO, TÍTULOS E VALORES MO",
        "ShortName": "MIRAE ASSET CCTVM LTDA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-28T05:25:19.347575-03:00",
        "DateUpdated": "2021-09-28T05:25:19.3476005-03:00"
    },
    {
        "COMPE": "448",
        "ISPB": "39669186",
        "Document": "39.669.186/0001-01",
        "LongName": "HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "HEMERA DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-08-17",
        "DatePixStarted": null,
        "DateRegistered": "2021-08-24T13:06:55.8926622-03:00",
        "DateUpdated": "2021-08-24T13:06:55.8926625-03:00"
    },
    {
        "COMPE": "449",
        "ISPB": "37555231",
        "Document": "37.555.231/0001-71",
        "LongName": "DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "DMCARD SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-10-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-10-23T20:55:03.4293187-03:00",
        "DateUpdated": "2022-10-08T12:07:31.8677543+00:00"
    },
    {
        "COMPE": "450",
        "ISPB": "13203354",
        "Document": "13.203.354/0001-85",
        "LongName": "FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A.",
        "ShortName": "FITBANK IP",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-06-30",
        "DatePixStarted": "2022-03-08 10:30:00",
        "DateRegistered": "2021-07-06T07:20:49.2254617-03:00",
        "DateUpdated": "2022-10-29T12:07:14.5229596+00:00"
    },
    {
        "COMPE": "451",
        "ISPB": "40475846",
        "Document": "40.475.846/0001-00",
        "LongName": "J17 - SOCIEDADE DE CRÉDITO DIRETO S/A",
        "ShortName": "J17 - SCD S/A",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-01-18",
        "DatePixStarted": null,
        "DateRegistered": "2022-02-01T08:17:39.0832206+00:00",
        "DateUpdated": "2022-02-01T08:17:39.0832325+00:00"
    },
    {
        "COMPE": "452",
        "ISPB": "39676772",
        "Document": "39.676.772/0001-83",
        "LongName": "CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "CREDIFIT SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-15",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-13T19:08:16.9817378-03:00",
        "DateUpdated": "2021-09-13T19:08:16.981738-03:00"
    },
    {
        "COMPE": "454",
        "ISPB": "41592532",
        "Document": "41.592.532/0001-42",
        "LongName": "MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "MÉRITO DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-11-23",
        "DatePixStarted": null,
        "DateRegistered": "2021-11-22T11:00:23.679022-03:00",
        "DateUpdated": "2021-11-22T11:00:23.6790225-03:00"
    },
    {
        "COMPE": "456",
        "ISPB": "60498557",
        "Document": "60.498.557/0001-26",
        "LongName": "Banco MUFG Brasil S.A.",
        "ShortName": "BCO MUFG BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.br.bk.mufg.jp",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711525-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3459364+00:00"
    },
    {
        "COMPE": "457",
        "ISPB": "39587424",
        "Document": "39.587.424/0001-30",
        "LongName": "UY3 SOCIEDADE DE CRÉDITO DIRETO S/A",
        "ShortName": "UY3 SCD S/A",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-10-26",
        "DatePixStarted": null,
        "DateRegistered": "2021-10-23T20:55:03.4293722-03:00",
        "DateUpdated": "2022-10-01T12:07:38.0638656+00:00"
    },
    {
        "COMPE": "458",
        "ISPB": "07253654",
        "Document": "07.253.654/0001-76",
        "LongName": "HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "HEDGE INVESTMENTS DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-09-08",
        "DatePixStarted": null,
        "DateRegistered": "2021-09-13T19:08:16.9817372-03:00",
        "DateUpdated": "2021-09-13T19:08:16.9817374-03:00"
    },
    {
        "COMPE": "459",
        "ISPB": "04546162",
        "Document": "04.546.162/0001-80",
        "LongName": "COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE",
        "ShortName": "CCM SERV. PÚBLICOS SP",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Troca de Modalidade"
        ],
        "Url": null,
        "DateOperationStarted": "2021-11-04",
        "DatePixStarted": null,
        "DateRegistered": "2021-11-22T11:00:23.6789568-03:00",
        "DateUpdated": "2022-06-23T13:51:34.957026+00:00"
    },
    {
        "COMPE": "460",
        "ISPB": "42047025",
        "Document": "42.047.025/0001-90",
        "LongName": "UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A",
        "ShortName": "UNAVANTI SCD S/A",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-01-18",
        "DatePixStarted": null,
        "DateRegistered": "2022-02-01T08:17:39.0832328+00:00",
        "DateUpdated": "2022-06-23T13:48:15.3661816+00:00"
    },
    {
        "COMPE": "461",
        "ISPB": "19540550",
        "Document": "19.540.550/0001-21",
        "LongName": "ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ShortName": "ASAAS IP S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-10-29",
        "DatePixStarted": "2021-11-01 09:00:00",
        "DateRegistered": "2021-11-22T11:00:23.6790202-03:00",
        "DateUpdated": "2022-10-22T12:07:53.6397295+00:00"
    },
    {
        "COMPE": "462",
        "ISPB": "39908427",
        "Document": "39.908.427/0001-28",
        "LongName": "STARK SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "STARK SCD S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-10-01",
        "DatePixStarted": "2021-11-29 20:00:00",
        "DateRegistered": "2021-10-23T20:55:03.4293732-03:00",
        "DateUpdated": "2021-12-13T12:13:48.6766832+00:00"
    },
    {
        "COMPE": "463",
        "ISPB": "40434681",
        "Document": "40.434.681/0001-10",
        "LongName": "AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA.",
        "ShortName": "AZUMI DTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-12-21",
        "DatePixStarted": null,
        "DateRegistered": "2022-01-05T11:00:34.5914098+00:00",
        "DateUpdated": "2022-01-05T11:00:34.5914283+00:00"
    },
    {
        "COMPE": "464",
        "ISPB": "60518222",
        "Document": "60.518.222/0001-22",
        "LongName": "Banco Sumitomo Mitsui Brasileiro S.A.",
        "ShortName": "BCO SUMITOMO MITSUI BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.smbcgroup.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115254-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3532168+00:00"
    },
    {
        "COMPE": "465",
        "ISPB": "40083667",
        "Document": "40.083.667/0001-10",
        "LongName": "CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "CAPITAL CONSIG SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-12-03",
        "DatePixStarted": null,
        "DateRegistered": "2021-12-13T12:13:48.9317922+00:00",
        "DateUpdated": "2021-12-13T12:13:48.9317923+00:00"
    },
    {
        "COMPE": "467",
        "ISPB": "33886862",
        "Document": "33.886.862/0001-12",
        "LongName": "MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS",
        "ShortName": "MASTER S/A CCTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2021-12-16",
        "DatePixStarted": null,
        "DateRegistered": "2021-12-13T12:13:48.9317806+00:00",
        "DateUpdated": "2021-12-13T12:13:48.9317918+00:00"
    },
    {
        "COMPE": "468",
        "ISPB": "04862600",
        "Document": "04.862.600/0001-10",
        "LongName": "PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "PORTOSEG S.A. CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": null,
        "DateOperationStarted": "2022-02-09",
        "DatePixStarted": "2022-03-14 14:00:00",
        "DateRegistered": "2022-02-16T07:02:06.0714205+00:00",
        "DateUpdated": "2022-06-23T13:51:35.2273425+00:00"
    },
    {
        "COMPE": "469",
        "ISPB": "07138049",
        "Document": "07.138.049/0001-54",
        "LongName": "LIGA INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
        "ShortName": "LIGA INVEST DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-03-09",
        "DatePixStarted": null,
        "DateRegistered": "2022-03-16T07:58:41.113663+00:00",
        "DateUpdated": "2022-08-05T12:06:01.5908103+00:00"
    },
    {
        "COMPE": "470",
        "ISPB": "18394228",
        "Document": "18.394.228/0001-79",
        "LongName": "CDC SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESADE PEQUENO PORTE LTDA.",
        "ShortName": "CDC SOCIEDADE DE CRÉDITO",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-03-15",
        "DatePixStarted": null,
        "DateRegistered": "2022-03-16T07:58:41.1136636+00:00",
        "DateUpdated": "2022-03-16T07:58:41.113664+00:00"
    },
    {
        "COMPE": "471",
        "ISPB": "04831810",
        "Document": "04.831.810/0001-40",
        "LongName": "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRES",
        "ShortName": "CECM SERV PUBL PINHÃO",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-07-18",
        "DatePixStarted": null,
        "DateRegistered": "2022-07-14T12:06:01.0960677+00:00",
        "DateUpdated": "2022-07-14T12:06:01.0960678+00:00"
    },
    {
        "COMPE": "473",
        "ISPB": "33466988",
        "Document": "33.466.988/0001-38",
        "LongName": "Banco Caixa Geral - Brasil S.A.",
        "ShortName": "BCO CAIXA GERAL BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bcgbrasil.com.br",
        "DateOperationStarted": "2009-03-31",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115073-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115074-03:00"
    },
    {
        "COMPE": "475",
        "ISPB": "10371492",
        "Document": "10.371.492/0001-85",
        "LongName": "Banco Yamaha Motor do Brasil S.A.",
        "ShortName": "BCO YAMAHA MOTOR S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2022-09-05",
        "DatePixStarted": null,
        "DateRegistered": "2022-09-02T12:07:04.257303+00:00",
        "DateUpdated": "2022-09-02T12:07:03.474729+00:00"
    },
    {
        "COMPE": "477",
        "ISPB": "33042953",
        "Document": "33.042.953/0001-71",
        "LongName": "Citibank N.A.",
        "ShortName": "CITIBANK N.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial Estrangeiro - Filial no país",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.citibank.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115054-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115056-03:00"
    },
    {
        "COMPE": "478",
        "ISPB": "11760553",
        "Document": "11.760.553/0001-69",
        "LongName": "GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "GAZINCRED S.A. SCFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2022-03-21",
        "DatePixStarted": null,
        "DateRegistered": "2022-03-31T08:12:19.2479082+00:00",
        "DateUpdated": "2022-06-23T13:51:35.2207262+00:00"
    },
    {
        "COMPE": "479",
        "ISPB": "60394079",
        "Document": "60.394.079/0001-04",
        "LongName": "Banco ItauBank S.A.",
        "ShortName": "BCO ITAUBANK S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.itaubank.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115247-03:00",
        "DateUpdated": "2021-05-21T23:01:28.1603338-03:00"
    },
    {
        "COMPE": "481",
        "ISPB": "43599047",
        "Document": "43.599.047/0001-26",
        "LongName": "SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "SUPERLÓGICA SCD S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": true,
        "CreditDocument": false,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-08-29",
        "DatePixStarted": null,
        "DateRegistered": "2022-08-24T12:36:20.0513082+00:00",
        "DateUpdated": "2022-09-10T12:06:04.146867+00:00"
    },
    {
        "COMPE": "482",
        "ISPB": "42259084",
        "Document": "42.259.084/0001-22",
        "LongName": "SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "SBCASH SCD",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-10-11",
        "DatePixStarted": null,
        "DateRegistered": "2022-10-04T12:08:42.7066127+00:00",
        "DateUpdated": "2022-10-04T12:08:42.7066128+00:00"
    },
    {
        "COMPE": "484",
        "ISPB": "36864992",
        "Document": "36.864.992/0001-42",
        "LongName": "MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
        "ShortName": "MAF DTVM SA",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-07-20",
        "DatePixStarted": null,
        "DateRegistered": "2022-07-16T12:07:46.2417741+00:00",
        "DateUpdated": "2022-07-16T12:07:46.2417743+00:00"
    },
    {
        "COMPE": "487",
        "ISPB": "62331228",
        "Document": "62.331.228/0001-11",
        "LongName": "DEUTSCHE BANK S.A. - BANCO ALEMAO",
        "ShortName": "DEUTSCHE BANK S.A.BCO ALEMAO",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.deutsche-bank.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115354-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4029972+00:00"
    },
    {
        "COMPE": "488",
        "ISPB": "46518205",
        "Document": "46.518.205/0001-64",
        "LongName": "JPMorgan Chase Bank, National Association",
        "ShortName": "JPMORGAN CHASE BANK",
        "Network": "RSFN",
        "Type": "Banco Comercial Estrangeiro - Filial no país",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.jpmorganchase.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115176-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115178-03:00"
    },
    {
        "COMPE": "492",
        "ISPB": "49336860",
        "Document": "49.336.860/0001-90",
        "LongName": "ING Bank N.V.",
        "ShortName": "ING BANK N.V.",
        "Network": "RSFN",
        "Type": "Banco Comercial Estrangeiro - Filial no país",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.ing.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115184-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115185-03:00"
    },
    {
        "COMPE": "495",
        "ISPB": "44189447",
        "Document": "44.189.447/0001-26",
        "LongName": "Banco de La Provincia de Buenos Aires",
        "ShortName": "BCO LA PROVINCIA B AIRES BCE",
        "Network": "RSFN",
        "Type": "Banco Comercial Estrangeiro - Filial no país",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115169-03:00",
        "DateUpdated": "2021-05-05T09:11:12.711517-03:00"
    },
    {
        "COMPE": "505",
        "ISPB": "32062580",
        "Document": "32.062.580/0001-38",
        "LongName": "Banco Credit Suisse (Brasil) S.A.",
        "ShortName": "BCO CREDIT SUISSE S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.csfb.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115036-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3443935+00:00"
    },
    {
        "COMPE": "506",
        "ISPB": "42066258",
        "Document": "42.066.258/0001-30",
        "LongName": "RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA",
        "ShortName": "RJI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-07-21",
        "DatePixStarted": null,
        "DateRegistered": "2022-07-19T12:06:21.2700855+00:00",
        "DateUpdated": "2022-07-19T12:06:21.2700856+00:00"
    },
    {
        "COMPE": "508",
        "ISPB": "61384004",
        "Document": "61.384.004/0001-05",
        "LongName": "AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "AVENUE SECURITIES DTVM LTDA.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-08-18",
        "DatePixStarted": null,
        "DateRegistered": "2022-08-13T12:30:35.4201882+00:00",
        "DateUpdated": "2022-08-13T12:30:35.4201883+00:00"
    },
    {
        "COMPE": "511",
        "ISPB": "44683140",
        "Document": "44.683.140/0001-87",
        "LongName": "MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ShortName": "MAGNUM SCD",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-08-22",
        "DatePixStarted": null,
        "DateRegistered": "2022-08-16T12:32:57.3964844+00:00",
        "DateUpdated": "2022-08-16T12:32:57.3964848+00:00"
    },
    {
        "COMPE": "512",
        "ISPB": "36266751",
        "Document": "36.266.751/0001-00",
        "LongName": "FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
        "ShortName": "FINVEST DTVM",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2022-08-01",
        "DatePixStarted": null,
        "DateRegistered": "2022-07-20T12:06:03.4386093+00:00",
        "DateUpdated": "2022-11-04T12:08:27.675962+00:00"
    },
    {
        "COMPE": "516",
        "ISPB": "36583700",
        "Document": "36.583.700/0001-01",
        "LongName": "FC FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
        "ShortName": "FC FINANCEIRA S.A. - CFI",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal e Troca de Modalidade"
        ],
        "Url": null,
        "DateOperationStarted": "2022-10-24",
        "DatePixStarted": null,
        "DateRegistered": "2022-10-21T12:08:38.2728811+00:00",
        "DateUpdated": "2022-10-21T12:08:38.2179312+00:00"
    },
    {
        "COMPE": "545",
        "ISPB": "17352220",
        "Document": "17.352.220/0001-87",
        "LongName": "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
        "ShortName": "SENSO CCVM S.A.",
        "Network": "Internet",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": null,
        "DateOperationStarted": "2010-09-27",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114892-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114893-03:00"
    },
    {
        "COMPE": "600",
        "ISPB": "59118133",
        "Document": "59.118.133/0001-00",
        "LongName": "Banco Luso Brasileiro S.A.",
        "ShortName": "BCO LUSO BRASILEIRO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.lusobrasileiro.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2021-12-17 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115232-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5509406+00:00"
    },
    {
        "COMPE": "604",
        "ISPB": "31895683",
        "Document": "31.895.683/0001-16",
        "LongName": "Banco Industrial do Brasil S.A.",
        "ShortName": "BCO INDUSTRIAL DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": "https://www.bancoindustrial.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115033-03:00",
        "DateUpdated": "2022-06-23T13:51:34.550029+00:00"
    },
    {
        "COMPE": "610",
        "ISPB": "78626983",
        "Document": "78.626.983/0001-63",
        "LongName": "Banco VR S.A.",
        "ShortName": "BCO VR S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.vrinvestimentos.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115452-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3543704+00:00"
    },
    {
        "COMPE": "611",
        "ISPB": "61820817",
        "Document": "61.820.817/0001-09",
        "LongName": "Banco Paulista S.A.",
        "ShortName": "BCO PAULISTA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário e Troca de Modalidade"
        ],
        "Url": "https://www.bancopaulista.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115321-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5517087+00:00"
    },
    {
        "COMPE": "612",
        "ISPB": "31880826",
        "Document": "31.880.826/0001-16",
        "LongName": "Banco Guanabara S.A.",
        "ShortName": "BCO GUANABARA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": false,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancoguanabara.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115029-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3478476+00:00"
    },
    {
        "COMPE": "613",
        "ISPB": "60850229",
        "Document": "60.850.229/0001-47",
        "LongName": "Omni Banco S.A.",
        "ShortName": "OMNI BANCO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": "https://www.bancopecunia.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2021-05-27 08:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7115268-03:00",
        "DateUpdated": "2022-06-23T13:51:35.2252774+00:00"
    },
    {
        "COMPE": "623",
        "ISPB": "59285411",
        "Document": "59.285.411/0001-13",
        "LongName": "Banco Pan S.A.",
        "ShortName": "BANCO PAN",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": [
            "Cheque Especial e Troca de Modalidade",
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos"
        ],
        "Url": "https://www.bancopan.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.711524-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5517011+00:00"
    },
    {
        "COMPE": "626",
        "ISPB": "61348538",
        "Document": "61.348.538/0001-86",
        "LongName": "BANCO C6 CONSIGNADO S.A.",
        "ShortName": "BCO C6 CONSIG",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115298-03:00",
        "DateUpdated": "2022-07-22T07:49:18.402843+00:00"
    },
    {
        "COMPE": "630",
        "ISPB": "58497702",
        "Document": "58.497.702/0001-02",
        "LongName": "BANCO LETSBANK S.A.",
        "ShortName": "BCO LETSBANK S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.intercap.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115221-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4026407+00:00"
    },
    {
        "COMPE": "633",
        "ISPB": "68900810",
        "Document": "68.900.810/0001-38",
        "LongName": "Banco Rendimento S.A.",
        "ShortName": "BCO RENDIMENTO S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro"
        ],
        "Url": "https://www.rendimento.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115412-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528196+00:00"
    },
    {
        "COMPE": "634",
        "ISPB": "17351180",
        "Document": "17.351.180/0001-59",
        "LongName": "BANCO TRIANGULO S.A.",
        "ShortName": "BCO TRIANGULO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha",
        "Products": null,
        "Url": "https://www.tribanco.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114888-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3692969+00:00"
    },
    {
        "COMPE": "637",
        "ISPB": "60889128",
        "Document": "60.889.128/0001-80",
        "LongName": "BANCO SOFISA S.A.",
        "ShortName": "BCO SOFISA S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": "https://www.sofisa.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115276-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3692893+00:00"
    },
    {
        "COMPE": "643",
        "ISPB": "62144175",
        "Document": "62.144.175/0001-20",
        "LongName": "Banco Pine S.A.",
        "ShortName": "BCO PINE S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Veículos"
        ],
        "Url": "https://www.pine.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115331-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5517163+00:00"
    },
    {
        "COMPE": "652",
        "ISPB": "60872504",
        "Document": "60.872.504/0001-23",
        "LongName": "Itaú Unibanco Holding S.A.",
        "ShortName": "ITAÚ UNIBANCO HOLDING S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.itau.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115272-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115274-03:00"
    },
    {
        "COMPE": "653",
        "ISPB": "61024352",
        "Document": "61.024.352/0001-71",
        "LongName": "BANCO VOITER S.A.",
        "ShortName": "BANCO VOITER",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bip.b.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.711528-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3484201+00:00"
    },
    {
        "COMPE": "654",
        "ISPB": "92874270",
        "Document": "92.874.270/0001-40",
        "LongName": "BANCO DIGIMAIS S.A.",
        "ShortName": "BCO DIGIMAIS S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial e Troca de Modalidade",
            "Consignado",
            "Pessoal",
            "Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115485-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5492921+00:00"
    },
    {
        "COMPE": "655",
        "ISPB": "59588111",
        "Document": "59.588.111/0001-03",
        "LongName": "Banco Votorantim S.A.",
        "ShortName": "BCO VOTORANTIM S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.bancovotorantim.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115243-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5534998+00:00"
    },
    {
        "COMPE": "707",
        "ISPB": "62232889",
        "Document": "62.232.889/0001-90",
        "LongName": "Banco Daycoval S.A.",
        "ShortName": "BCO DAYCOVAL S.A",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiliário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.daycoval.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115338-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5486005+00:00"
    },
    {
        "COMPE": "712",
        "ISPB": "78632767",
        "Document": "78.632.767/0001-20",
        "LongName": "Banco Ourinvest S.A.",
        "ShortName": "BCO OURINVEST S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.ourinvest.com.br",
        "DateOperationStarted": "2014-01-28",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115456-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115458-03:00"
    },
    {
        "COMPE": "720",
        "ISPB": "80271455",
        "Document": "80.271.455/0001-80",
        "LongName": "BANCO RNX S.A.",
        "ShortName": "BCO RNX S.A.",
        "Network": "RSFN",
        "Type": null,
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Pessoal e Veículos"
        ],
        "Url": null,
        "DateOperationStarted": "2021-02-24",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711546-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528348+00:00"
    },
    {
        "COMPE": "739",
        "ISPB": "00558456",
        "Document": "00.558.456/0001-71",
        "LongName": "Banco Cetelem S.A.",
        "ShortName": "BCO CETELEM S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado e Troca de Modalidade"
        ],
        "Url": "https://www.cetelem.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7113315-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4000363+00:00"
    },
    {
        "COMPE": "741",
        "ISPB": "00517645",
        "Document": "00.517.645/0001-04",
        "LongName": "BANCO RIBEIRAO PRETO S.A.",
        "ShortName": "BCO RIBEIRAO PRETO S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal e Veículos"
        ],
        "Url": "https://www.brp.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7112988-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5528273+00:00"
    },
    {
        "COMPE": "743",
        "ISPB": "00795423",
        "Document": "00.795.423/0001-45",
        "LongName": "Banco Semear S.A.",
        "ShortName": "BANCO SEMEAR",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado e Pessoal"
        ],
        "Url": "https://www.bancosemear.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2021-12-22 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7113451-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4000454+00:00"
    },
    {
        "COMPE": "745",
        "ISPB": "33479023",
        "Document": "33.479.023/0001-80",
        "LongName": "Banco Citibank S.A.",
        "ShortName": "BCO CITIBANK S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado",
            "Imobiliário e Pessoal"
        ],
        "Url": "https://www.citibank.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2021-09-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115077-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4019995+00:00"
    },
    {
        "COMPE": "746",
        "ISPB": "30723886",
        "Document": "30.723.886/0001-62",
        "LongName": "Banco Modal S.A.",
        "ShortName": "BCO MODAL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Destinatário",
        "Products": null,
        "Url": "https://www.bancomodal.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7115014-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3692436+00:00"
    },
    {
        "COMPE": "747",
        "ISPB": "01023570",
        "Document": "01.023.570/0001-60",
        "LongName": "Banco Rabobank International Brasil S.A.",
        "ShortName": "BCO RABOBANK INTL BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.rabobank.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7113885-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7114193-03:00"
    },
    {
        "COMPE": "748",
        "ISPB": "01181521",
        "Document": "01.181.521/0001-55",
        "LongName": "BANCO COOPERATIVO SICREDI S.A.",
        "ShortName": "BCO COOPERATIVO SICREDI S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo Cooperativo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Consignado",
            "Imobiliário",
            "Pessoal",
            "Veículos e Outros Créditos"
        ],
        "Url": "https://www.sicredi.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114428-03:00",
        "DateUpdated": "2022-06-23T13:51:34.5485763+00:00"
    },
    {
        "COMPE": "751",
        "ISPB": "29030467",
        "Document": "29.030.467/0001-66",
        "LongName": "Scotiabank Brasil S.A. Banco Múltiplo",
        "ShortName": "SCOTIABANK BRASIL",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.br.scotiabank.com",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114999-03:00",
        "DateUpdated": "2022-07-22T07:49:18.401793+00:00"
    },
    {
        "COMPE": "752",
        "ISPB": "01522368",
        "Document": "01.522.368/0001-82",
        "LongName": "Banco BNP Paribas Brasil S.A.",
        "ShortName": "BCO BNP PARIBAS BRASIL S A",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bnpparibas.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7114435-03:00",
        "DateUpdated": "2022-06-23T13:48:15.3417126+00:00"
    },
    {
        "COMPE": "753",
        "ISPB": "74828799",
        "Document": "74.828.799/0001-45",
        "LongName": "Novo Banco Continental S.A. - Banco Múltiplo",
        "ShortName": "NOVO BCO CONTINENTAL S.A. - BM",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": [
            "Consignado"
        ],
        "Url": "https://www.nbcbank.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.711543-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4030981+00:00"
    },
    {
        "COMPE": "754",
        "ISPB": "76543115",
        "Document": "76.543.115/0001-94",
        "LongName": "Banco Sistema S.A.",
        "ShortName": "BANCO SISTEMA",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": null,
        "CreditDocument": null,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.btgpactual.com",
        "DateOperationStarted": "2015-07-15",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115441-03:00",
        "DateUpdated": "2021-05-05T09:11:12.7115443-03:00"
    },
    {
        "COMPE": "755",
        "ISPB": "62073200",
        "Document": "62.073.200/0001-21",
        "LongName": "Bank of America Merrill Lynch Banco Múltiplo S.A.",
        "ShortName": "BOFA MERRILL LYNCH BM S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo",
        "PixType": null,
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.ml.com",
        "DateOperationStarted": "2007-11-09",
        "DatePixStarted": null,
        "DateRegistered": "2021-05-05T09:11:12.7115324-03:00",
        "DateUpdated": "2022-06-23T13:48:15.355136+00:00"
    },
    {
        "COMPE": "756",
        "ISPB": "02038232",
        "Document": "02.038.232/0001-64",
        "LongName": "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB",
        "ShortName": "BANCO SICOOB S.A.",
        "Network": "RSFN",
        "Type": "Banco Múltiplo Cooperativo",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": "Banco folha e Destinatário",
        "Products": [
            "Cheque Especial",
            "Consignado",
            "Imobiário",
            "Outros Créditos",
            "Pessoal",
            "Troca de Modalidade e Capital de Giro",
            "Veículos"
        ],
        "Url": "https://www.bancoob.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2020-11-03 06:30:00",
        "DateRegistered": "2021-05-05T09:11:12.7114457-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4001995+00:00"
    },
    {
        "COMPE": "757",
        "ISPB": "02318507",
        "Document": "02.318.507/0001-13",
        "LongName": "BANCO KEB HANA DO BRASIL S.A.",
        "ShortName": "BCO KEB HANA DO BRASIL S.A.",
        "Network": "RSFN",
        "Type": "Banco Comercial",
        "PixType": "DRCT",
        "Charge": true,
        "CreditDocument": true,
        "SalaryPortability": null,
        "Products": null,
        "Url": "https://www.bancokeb.com.br",
        "DateOperationStarted": "2002-04-22",
        "DatePixStarted": "2021-08-09 10:00:00",
        "DateRegistered": "2021-05-05T09:11:12.7114464-03:00",
        "DateUpdated": "2022-07-22T07:49:18.4002203+00:00"
    }
]