export class Invoice {
    constructor(
        public id?: number,
        public user_id?: number,
        public file_id?: number,
        public boleto_file_id?: number,
        public entity_id?: number,
        public bank_account_id?: number,
        public planning_id?: number,
        public year?: string,
        public conciliated_amount?: string,
        public reference?: string,
        public number?: string,
        public amount?: string,
        public cost_type?: string,
        public is_concilliated?: boolean,
        public was_paid?: boolean,
        public cost_action?: string,
        public observations?: string,
        public payment_method?: any,
        public due_date?: string,
        public deleted_at?: string,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public file?: any,
        public boleto?: any,
        public planning?: any,
        public expenses_invoices_installments?: any[]
    ) { }
}